:root {
    --background-color:#000 !important;
    --primary-color:#08d1d1;
    --secondary:#08d1d1;
    --btn-bg:#08d1d1;
    --text-color:#fff;
    --header-bg:#000;
  }
  
  [data-theme="dark"] {
    --background-color:#000 !important;
    --primary-color:#08d1d1;
    --secondary:#08d1d1;
    --theme-bg:#000;
  
    --btn-bg:#08d1d1;
    --btn-bg-light:#08d1d1;
    --btn-hover:#08d1d1;
    --btn-border:#08d1d1;
    --text-primary:#08d1d1;
    --text-color:#fff;
    --text-white:#fff;
    --text-black:#000;
    --theme-text:#000;
  
    --header-bg:#00191e;
    --footer-bg:#00191e;
    --section-bg:#00191e;
    --card-bg:#000;
    --card-bg1:#00191e;
  }
  
  [data-theme="light"] {
    --background-color:#fff !important;
    --primary-color:#08d1d1;
    --secondary:#08d1d1;
    --theme-bg:#fff;
  
    --btn-bg:#08d1d1;
    --btn-bg-light:#08d1d1;
    --btn-hover:#08d1d1;
    --btn-border:#08d1d1;
  
    --text-primary:#08d1d1;
    --text-color:#000;
    --text-white:#fff;
    --text-black:#000;
    --theme-text:#fff;
  
  
    --header-bg:#f7f7f7;
    --footer-bg:#f7f7f7;
    --section-bg:#f7f7f7;
    --card-bg:#e3e6e6;
    --card-bg1:#f7f7f7;
  
  }

/* login css */
.sec01 {
    background: linear-gradient(90deg, #00e6e6 35%, #FFF 15%);
    padding: 80px 20px;
    height: 100vh;
}


.sec01_img {
    width: 100%;
}

a {
    text-decoration: none;
}

.otp_btn {
    background-color: #00e6e6;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border-radius: 8px;
    margin-left: 20px;
}

.form_input {
    width: 100%;
    padding: 9px;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    background-color: #eaeaea;
    font-size: 18px;
}

input#phone {
    padding: 9px 9px 9px 85px;
}

input:focus-visible {
    outline: 0px !important;
}

.iti--separate-dial-code .iti__selected-flag {
    background-color: rgb(0 0 0 / 0%) !important;
}

.form_lable {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
    margin-top: 10px;
}

ul#iti-2__country-listbox {
    border: 0px !important;
    border-radius: 10px !important;
}

.iti__arrow {
    display: none !important;
}

.input_mob {
    width: 100%;
}

.iti.iti--allow-dropdown.iti--separate-dial-code {
    width: 100% !important;
}

.align-self {
    align-self: center;
}

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.w {
    color: var(--text-color);
}
.w1 {
    color: var(--text-color);
}

.b {
    color: #000;
}

.submit_btn {
    padding: 9px 40px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    background-color: #00e6e6;
    color: #fff;
    margin-top: 20px;
}

/* button#home-tab,
#profile-tab {
    border-style: solid;
    border-color: #000 !important;
    border-radius: 0px !important;
    font-size: 18px;
    font-weight: 600;
    padding: 5px !important;
}

#home-tab {
    border-width: 0px 1px 0px 0px !important;
}

#profile-tab {
    border-width: 0px 0px 0px 1px !important;
} */


/*about .css  */
.about1 {
    text-align: center;

}

img .about1 {
    width: 300px !important;
}

#sa {
    width: 500px !important;
    margin-top: 20px;
}

@media (min-width: 320px)and (max-width: 769px) {
    .about1 {
        width: 10px !important;
        text-align: center;
    }

    img .about1 {
        width: 100px !important;
    }

    #sa {
        width: 300px !important;
        margin-top: 20px;
    }

    #a {
        text-align: center;
        margin-top: 30px;
        /* margin-bottom: 20px !important; */
        color: white !important;
    }

    .b {
        align-items: center;
        background-color: #00191e;
        border-radius: 20px;
        display: flex;
        gap: 10px;
        /* height: 30px; */
        margin-bottom: 0px;
        padding: 105px;
        flex-direction: column !important;

    }

    .c {
        color: white !important;
        text-align: center;
        font-size: 10px !important;
    }

    #do {
        margin-top: 10px;
        width: 200px;
    }

    .col-lg-5.col-md-5.col-sm-12 {
        margin-top: -44px;
    }

    .qw {
        color: white !important;
        text-align: center;
        font-size: 11px !important;
        /* margin-top: 70px; */

    }
}

.a {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 90px !important;
    color: white !important;
}

.b {
    align-items: center;
    background-color: #00191e;
    border-radius: 20px;
    display: flex;
    gap: 50px;
    height: 370px;
    margin-bottom: 80px;
    padding: 105px;

}

.c {
    color: white !important;
    padding-left: 30px !important;
}

.qw {
    color: white !important;
    padding-left: 30px !important;
}

.wq {
    color: white !important;
    padding-left: 30px !important;
    margin-top: 20px !important;
}

.cp {
    color: white !important;
    padding-left: 30px !important;

}

.d {
    margin-top: 10px;
    width: 400px;
    justify-content: center;

}

.dp {
    margin-top: 10px;
    width: 400px;
    /* justify-content: center; */
    padding-left: 10px;


}

.i {
    margin-left: 100px !important;
}

/* carre css */
#m {
    margin-bottom: 120px;
    margin-top: 105px;
    text-align: center;
    font-size: 30px !important;
}

.m {
    color: #00e6e6 !important;

}

.sectio {
    margin-top: 100px;
    margin-bottom: 100px;
}

.sectioh1 {
    font-size: 80px !important;
    color: white;
}

.secionp1 {
    color: gray !important;
    ;
    font-size: 16px;
    /* margin-bottom: 10px !important; */
}

.btn2 {
    background: #00e6e6 !important;
    padding: 10px 30px;
    border-radius: 5px;
    text-decoration: none;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    margin-top: 10% !important;
}

.cardcaree {
    align-items: center;
    background-color: #00191e;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
    padding: 60px 120px;
}

.carre150 {
    font-size: 55px;
    font-weight: 500px;
    color: white;
}

.carrecountry {
    color: #00e6e6;
    font-size: 22px;
    font-weight: 300;
}

.mo {
    font-size: 20px;
    color: #00e6e6 !important;
}

.sectioh3 {
    font-size: 40px;
    color: white !important;
}

.secionp2 {
    font-size: 15px !important;
    color: #9295a6;
}

.user-icon {
    font-size: 70px;
    color: #00e6e6;
}

.jobCard_card {
    align-items: center;
    background-color: #00191e;
    border-radius: 10px;
    display: flex;
    gap: 30px;
    height: 135px;
    padding: 30px;
}

.jobCard_textContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
}

span.\.jobCard_textContainer-span {
    color: white !important;
    font-size: 20px;
    font-weight: 500;
}

.jobCard_textContainerp {
    color: gray !important;
    font-size: 13px;
    margin: 0;
}

.uo {
    margin-top: 40px;
}

.jobsGrid_linkContainer {
    align-items: center;
    display: flex;
    font-size: 18px;
    gap: 10px;
    justify-content: center;
    margin: 55px 0;
    text-decoration: underline;
    text-decoration-color: #00e6e6;
}

.user-icon-circle {
    background-color: white !important;
    border-radius: 10px !important;
}

/* Security css */
.companySecurity {
    color: #00e6e6;
    font-size: 30px;
    width: 400px;
}

.companySecurity_headerContainer {
    flex: 0.55 1;
    margin-bottom: 120px;
    margin-top: 105px;
    text-align: left;

}

.companySecurity_headerContainer-h1 {
    font-size: 46px;
    color: white !important;
}

.companySecurity_headerContainer-p {
    color: gray !important;
    font-size: 15px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@media (min-width: 320px)and (max-width: 769px) {
    .companySecurity_pageContainer-img {
        flex: 0.25 1;
        width: 275px;
    }
}

/* support  css */
.contactSupport_headerContainer-section {
    margin-bottom: 120px;
    margin-top: 105px;
    text-align: center;
}

.contactSupport_headerContainer-span {
    color: #00e6e6;
    font-size: 30px;
}

.contactSupport_headerContainer-h1 {
    font-size: 80px;
    color: white !important;
}

.contactSupport_headerContainer-p {
    color: gray;
    font-size: 16px;
    text-align: center;
}

.contactSupport_cardsContainer-section2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 150px;
}

.contactSupport_card-section2 {
    align-items: center;
    background-color: #00191e;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    height: 262px;
    justify-content: space-between;
    outline: 1.75px solid #0000;
    padding: 20px;
    text-align: center;
    transition: outline-color .3s ease;
    width: 300px;
}

.contactSupport_card-first-child-img {
    align-items: center;
    background-color: #000;
    border-radius: 14px;
    display: flex;
    height: 75px;
    justify-content: center;
    width: 75px;
    margin-left: 10px !important;
}

.user-email {
    width: 40px !important;
    height: 40px !important;
    color: #00e6e6;

}

.asi {
    margin-left: 26px;
    /* align-self: center; */
    gap: 110px;
    /* text-align: center; */
    position: relative;
    display: flex;
    justify-content: center
}

@media (min-width: 320px)and (max-width: 769px) {
    .asi {
        gap: 10px;
        display: block;

    }

    .contactSupport_headerContainer-p {
        color: gray;
        font-size: 15px;
        text-align: center;
        margin-left: 32px;
        margin-top: 48px !important;



    }

    .contactSupport_headerContainer-h1 {
        font-size: 35px;
        color: white !important;
        text-align: center;
        margin-left: 34px;
    }

    .contactSupport_headerContainer-section {
        margin-bottom: 120px;
        margin-top: 105px;
        text-align: center;
    }


}

.dsd {
    margin-left: 10px !important;
}

.contactSupport_card-email-span {
    font-size: 17px;
    color: #FFF;
}

.contactSupport_card1-p {
    color: gray !important;
    font-size: 13px;
}

.contactSupport_card-section2:hover {
    border: 1px solid;
    border-radius: 15px;
    border-color: #00e6e6;
}
/* Faq */
.contactSupport_headerContainer-span-1{
    color: #00e6e6;
    font-size: 30px;
    margin-bottom: 10px!important;
}
/* .vi{
    margin-top: 20px!important;
} */