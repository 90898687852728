:root {
    --background-color: #000 !important;
    --primary-color: #08d1d1;
    --secondary: #08d1d1;
    --btn-bg: #08d1d1;
    --text-color: #fff;
    --header-bg: #000;

}

[data-theme="dark"] {
    --background-color: #000 !important;
    --primary-color: #08d1d1;
    --secondary: #08d1d1;
    --theme-bg: #000;

    --btn-bg: #08d1d1;
    --btn-bg-light: #08d1d1;
    --btn-hover: #08d1d1;
    --btn-border: #08d1d1;
    --text-primary: #08d1d1;
    --text-color: #fff;
    --text-white: #fff;
    --text-black: #000;
    --theme-text: #000;
    --color-success: #22bb33;
    --color-danger: #f94747;

    --header-bg: #00191e;
    --footer-bg: #00191e;
    --section-bg: #00191e;
    --card-bg: #000;
    --card-bg1: #00191e;

    --border-color: #08d1d1;

    --input-bg: #00191e;
    --input-border: #00191e;
    --input-color: #c4c4c4;


    --border-c: #202423;
    --border-buy: #023e3e;
    --table-hover: #000;

    --select-bg: #00191e;
    --select-border: #ccced926;



}

[data-theme="light"] {
    --background-color: #fff !important;
    --primary-color: #08d1d1;
    --secondary: #08d1d1;
    --theme-bg: #fff;

    --btn-bg: #08d1d1;
    --btn-bg-light: #08d1d1;
    --btn-hover: #08d1d1;
    --btn-border: #08d1d1;

    --text-primary: #08d1d1;
    --text-color: #000;
    --text-white: #fff;
    --text-black: #000;
    --theme-text: #fff;
    --color-success: #22bb33;
    --color-danger: #f94747;


    --header-bg: #f7f7f7;
    --footer-bg: #f7f7f7;
    --section-bg: #f7f7f7;
    --card-bg: #e3e6e6;
    --card-bg1: #f7f7f7;

    --border-color: #08d1d1;

    --input-bg: #f7f7f7;
    --input-border: #f7f7f7;
    --input-color: #1c1717;

    --border-c: #dadada;
    --border-buy: #d3d3d3;
    --table-hover: #e8e8e8;

    --select-bg: #f7f7f7;
    --select-border: #e8e8e8;
}

.market-main-page {
    margin-top: 20px;
    margin-bottom: 20px;
}

.market-slider {
    background: var(--card-bg1);
    padding: 30px 30px;
    border-radius: 10px;
}

.slider-header {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.slider-top-content h4 {
    color: var(--text-color);
}

.slider-top-content p {
    color: var(--text-color);
}

.slider-top-srh {
    position: relative;
}

.slider-top-srh input {
    background: var(--card-bg);
    border: 1px solid #ccced926;
    padding: 7px 20px 7px 30px;
    /* width: 300px; */
    border-radius: 10px;
    color: #6c7080;
}

/* #6c7080 */
.slider-top-srh input::placeholder {
    color: var(--input-color);
}

.slider-top-srh input:focus {
    border: 1px solid var(--input-border);
    outline: none;
}

.srh-icon {
    position: absolute;
    color: var(--input-color);
    font-size: 20px;
    left: 7px;
    top: 10px;
}

.market-card {
    display: flex !important;
    background-color: var(--card-bg) !important;
    padding: 20px 20px;
    border-radius: 10px;
    border-color: var(--border-c) !important;


}

.market-card1 {
    display: flex !important;
    background-color: var(--card-bg) !important;
    padding: 30px 20px;
    border-radius: 10px;
    align-items: center;
}

.mc1 {
    width: 100%;
}

.mc1-flex {
    display: flex;
    align-items: center;
    gap: 10px;
}

.mc1 img {
    width: 30px;
}

.mc1 h5 {
    font-size: 14px;
    color: var(--text-color);
    margin-bottom: 0;
}

.mc1 span {
    margin-left: 20px;
    color: var(--text-color);
}

.mc1 h6 {
    margin-bottom: 0;
    color: var(--text-color);
    font-size: 13.5px;
}

.up-down-count {
    color: var(--color-success);
    font-size: 13px;
}

.up-down-count span {
    color: var(--color-success);
    margin-left: 0px;
}

.down-trade {
    color: #d63c12 !important;
    font-size: 13px;
}

.mc2 img {
    width: 100px;
}

.market-table {
    
    margin-top: 30px;
}

.market-table {
    background: var(--card-bg1);
    padding: 20px;
    border-radius: 10px;
}

.mt-table {
    background: var(--card-bg1) !important;
    padding-bottom: 0;
    margin-bottom: 0px !important;

}
/* .mt-table th,
td {
    background: transparent !important;
    color: #6c7080 !important;

} */

.mt-table,
td {
    background: transparent !important;
    color: var(--input-color) !important;

}

.mt-table thead tr {
    border-bottom: 0px solid var(--border-c);
    font-size: 13px;
    font-weight: 400;
    ;
}

.mt-table thead tr th {
    padding: 15px 10px !important;
    font-size: 13px !important;
    font-weight: 500;
}

.mt-table tbody tr {
    border-bottom: 0px solid var(--card-bg1);
    background: var(--card-bg1);
}

.mt-table tbody tr:hover {
    background: var(--table-hover);
}

.mt-table tbody tr td {
    padding: 15px 10px !important;
    font-size: 13px;
    font-weight: 500;
    color: #e4e4e4;

}

.markit-td1 {
    display: flex;
    align-items: center;
    gap: 5px;

}

.markit-td1 img {
    width: 25px;
}

.coin-name {
    font-size: 13px;
    font-weight: 500;
    color: #e4e4e4;
}

.coin-tag-name {
    font-size: 13px;
    color: var(--primary-color);
    background-color: var(--card-bg);
    padding: 2px 7px;
    border-radius: 5px;

}

.up-trade {
    color: var(--color-success) !important;
}

.down-trade {
    color: var(--color-danger) !important;
}

.action-btn {
    /* display: flex; */
    /* align-items: center; */
    /* gap: 10px; */
    width: 250px;
}

.action-btn a {
    color: var(--primary-color);
    background: var(--card-bg);
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.action-btn a:hover {
    color: #000;
    background: #00e6e6;
}

.link-btn {
    margin-left: 5px;
    font-size: 13px;
}

.market-table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--section-bg);
    padding: 20px 20px;
    border-top: 1px solid var(--border-buy);
}

.total-page p {
    color: var(--text-color);
    margin-bottom: 0;
    font-size: 13px;
}

.page-link1 {
    background: var(--card-bg) !important;
    border: none !important;
    color: var(--primary-color) !important;
}

.page-link1:focus {
    border: 0px !important;
    outline: 0px !important;
}

.chart-image {
    width: 150px;
    margin-right: 20px;


}

.chart-image img {
    width: 100px;
}

.td-1 {
    width: 220px;
}

@media (max-width: 576px) {
    .slider-header {
        flex-direction: column-reverse;
        gap: 30px;
    }

    .market-slider {
        padding: 20px 15px !important;
    }

    .slider-top-srh {
        display: none;
    }
}






/* convert-main */


.convert-main {
    padding: 40px 0px;
}

.convert-page-title h2 {
    color: #fff;
    margin-bottom: 50px;
}

.card-main {
    width: 600px;
    margin: 0 auto;
}

.convert-card {
    background: #00191e !important;
    padding: 30px;
}

.convert-d-flex {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.convert-card-header {}

.convert-card-header p {
    color: #fff;
}

.convert-card-header input {
    background: #00191e;
    border: 1px solid #ccced926;
    padding: 7px 20px 7px 30px;
    /* width: 300px; */
    border-radius: 10px;
    color: #6c7080;
}

.convert-card-header input:focus {
    outline: none;

}

.card-inner-box {
    background: #000;
    margin-top: 20px;
    padding: 30px;
    border-radius: 10px;
}

.convert-btn {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.convert-btn button {
    color: #00e6e6;
    background: #00191e;
}

.convert-btn button:hover {
    color: #00191e;
    background: #00e6e6;
}

.convert-icon {
    font-size: 30px;
}

/*select-option*/
.css-13cymwt-control {
    background: var(--select-bg) !important;
    border: 1px solid var(--select-border) !important;
    border-radius: 5px !important;
    outline: 0px !important;
    overflow: hidden !important;


}

.css-13cymwt-control:hover {
    border: 1px solid var(--primary-color) !important;

}

.css-13cymwt-control:focus {
    border: 1px solid var(--primary-color) !important;
    outline: var(--primary-color) !important;

}

.css-13cymwt-control:focus-visible {
    border: 1px solid var(--primary-color) !important;
    outline: var(--primary-color) !important;
}

.css-1fdsijx-ValueContainer {
    width: 200px;
    background: var(--select-bg);
    border: 0px solid var(--select-border) !important;
    border-radius: 0px;
    color: var(--text-color);
}

span.css-1u9des2-indicatorSeparator {
    display: none;
}

.css-1xc3v61-indicatorContainer {
    background: var(--select-bg);
}

.css-1xc3v61-indicatorContainer:hover {
    background: var(--select-bg) !important;
}

.css-1hb7zxy-IndicatorsContainer {
    background: var(--select-bg) !important;
}

.css-1nmdiq5-menu {
    background-color: var(--card-bg1) !important;
    border: 1px solid #00e6e6 !important;
    color: #00e6e6 !important;
    z-index: 999 !important;
    display: block !important;
}

.css-1nmdiq5-menu option {
    background-color: var(--color-danger) !important;
}

.css-qbdosj-Input {
    padding-bottom: 4px !important;
}

.css-qbdosj-Input:focus-visible {
    outline: 0px !important;
}

.css-1jqq78o-placeholder{
    grid-area: 1 / 1 / 2 / 3;
    color: rgb(128, 128, 128);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
}
.cselect-option {
    color: var(--text-color) !important;
}

.cselect-option {
    color: var(--text-color) !important;

}

.css-t3ipsp-control:hover {
    border-color: var(--primary-color) !important;
}

.css-t3ipsp-control:active {
    border-color: var(--primary-color) !important;
}

.css-t3ipsp-control {
    background-color: var(--card-bg1) !important;
    border-color: #023e3e !important;
    border-radius: 5px !important;
    border-style: solid;
    border-width: 1px !important;
    box-shadow: 0 0 0 1px #00191e !important;
    box-sizing: border-box;
    overflow: hidden !important;
}

.form-group-flex {
    display: flex;
    align-items: center;
}

@media (max-width: 992px) {
    .market-table {
        overflow-x: scroll;
    }

    .mt-table {
        width: 1000px !important;
    }
}



/* trade css */
.trade-column1 {
    background-color: var(--section-bg);
    padding: 20px;
    border-radius: 10px;
}
/* .trade-column2-h{
    height: 340px !important;
} */

/*trade-table-row*/


.trade-table1 {
    margin-top: 10px;
    background: var(--section-bg);

}

.tradeing-table-row {
    position: relative !important;
}

.tradeing-table-row td,
th {
    background: transparent !important;
    color: var(--text-color) !important;
    font-size: 11px !important;
    font-weight: 300;
    padding: 5px !important;
}

.tradeing-table-row thead {

    position: sticky;
    top: 0;
    padding: 10px !important;

}

.t-tr-row {
    display: flex;
    justify-content: space-between;
}

.t-trbody-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--section-bg) !important;
}

.tradeing-table-row tbody a {
    display: flex;
    background-color: var(--section-bg) !important;
    color: var(--section-bg) !important;
}

.tradeing-table-row thead tr {
    border-bottom: 1px solid var(--border-c) !important;
    background-color: var(--section-bg) !important;

}

.tradeing-table-row tbody tr {
    border-bottom: 1px solid var(--border-c) !important;
    padding: 00px 0px !important;
}

.tradeing-table-row tbody tr:hover {
    background-color: var(--table-hover);
    border-bottom: 1px solid var(--border-c) !important;
    border-radius: 5px;
}

.tradeing-table-row tbody tr td {
    border: 0px solid transparent !important;
    overflow: hidden;
    padding: 3px 0px;
}

.trade-table1 {
    position: relative;

}

.table-scroll1 {
    overflow-y: scroll;
    height: 350px;
}

.table-scroll2 {
    overflow-y: scroll;
    height: 210px;
}
.table-scroll3 {
    overflow-y: scroll;
    height: 240px;
}
.scroll-body-div {
    /* height: 200px; */
}

.scroll-tbody {
    /* height: 200px !important; */
}

.col-td1 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.col-td1 img {
    width: 25px;
}

.col-td1 li {
    list-style: none;

}

.coin-name {
    font-size: 13px;
    font-weight: 400;
    color: var(--input-color);
}

.coin-c {
    color: #00e6e6 !important;
    font-size: 13px;
    font-weight: 400;
}

.td-btn {
    background: var(--card-bg);
    color: var(--primary-color);
    padding: 2px 5px;
    margin-top: 5px;
    border-radius: 5px;
}

.col-td2 {
    width: 120px;
}

.col-td2 li {
    list-style: none;

}

.td-value1 {
    color: var(--primary-color) !important;
    font-size: 13px;
    font-weight: 400;
}

.td-value-up {
    color: var(--color-success);
}

.td-value-down {
    color: var(--color-danger);
}

.up-ic {
    margin-left: 5px;
    color: var(--color-success);
}

.down-ic {
    margin-left: 5px;
    color: var(--color-danger);
}


/*create-new-table-markit*/

/*trade-mid-card*/
.trade-mid-card {
    background: var(--section-bg) !important;
    padding: 10px !important;
}

.t-mid-header {
    display: flex;
    align-items: center;
}

.t-mid-left {
    width: 40%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.t-mid-left img {
    width: 20px;
}

.uc-name {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
}

.uc-name-color {
    color: var(--primary-color) !important;
    background: var(--card-bg);
    padding: 2px 3px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 400;
}

.t-mid-right {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: end;
}

.markit-table-dropdown{
  position: relative;
}
.mt-dropdown{
    display: flex;
    border: 1px solid var(--primary-color) !important;
    border-radius: 10px;
    padding: 3px 10px !important;
    color: var(--primary-color) !important;
}

.mt-dropdown-body{
   
    min-width: 300px !important;
    /* border: .5px solid var(--border-color); */
    border-radius: 10px;
    padding: 10px 10px !important;
    box-shadow: 0px 0px 8px -5px var(--primary-color);
    overflow: hidden;
    background-color: var(--section-bg) !important;
 
   
    transition: .2s;
 
    
}
.add-dropdown-body{
    visibility: visible;
    opacity: 1;
    height: auto;
    
}

.tm-right-value1 {
    font-size: 20px;
    font-weight: 900;
    color: var(--color-success);
}

.tm-right-value1-red {
    color: var(--color-danger) !important;
    font-size: 13px;
}

.green-value {
    color: var(--color-success);
}

.red-value {
    color: var(--color-danger);
}

.h-text {
    color: var(--input-color);
    font-size: 13px;
}

.card-mid-content {
    margin-top: 20px;
    display: flex;
    /* align-items: center; */
}

.card-inner-box1 {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.box1 {
    background: var(--card-bg);
    width: 30%;
    border-radius: 10px;
    padding: 10px;
}

.box1 p {
    margin-bottom: 5px;
    color: var(--input-color);
    font-size: 12px;
    font-weight: 400;
}

.box1-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ques-icon {
    color: var(--input-color);
}

.box1-footer span {
    color: var(--input-color);
    font-size: 12px;
    font-weight: 400;
}

.heartbeet-icon {
    color: #00e6e6 !important;
}

.range-div {
    width: 40%;
}

.range-header {
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--text-color) !important;
}

.up-down-icon {
    font-size: 14px;
}

.range-header p {
    margin-bottom: 0;
    font-size: 14px;
}

.range-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.range-footer p {
    margin-bottom: 0px;
    color: var(--text-color) !important;
    font-size: 13px;
    font-weight: 400;
}

/*orderbook-card*/
.orderbook-card {
    background-color: var(--section-bg);
    border-radius: 10px;
    padding: 10px;
}

.order-header {
    border-bottom: 1px solid var(--border-c);
    padding-bottom: 5px;
}

.order-header h4 {
    color: var(--text-color) !important;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
}

.group-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.group-div p {
    color: var(--text-color);
    margin-bottom: 0;
    font-size: 13px;
}

td.red-text {
    color: var(--color-danger) !important;
}

td.green-text {
    color: var(--color-success) !important;
}

tr.tr-title {
    margin-top: 20px !important;
    border-bottom: 1px solid #023e3e !important;
    border-top: 1px solid #023e3e;
}

.tr-title:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}

.buy-sell-main {
    background: var(--section-bg);
    padding: 5px 10px;
    border-radius: 10px;
    margin-top: 0px;
}

.buysell-tab {
    border-bottom: 0px solid transparent !important;
    gap: 10px
}

.buysell-link {
    background-color: var(--card-bg) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--border-buy) !important;
    color: var(--input-color) !important;
    font-size: 14px !important;
    padding: 5px 20px !important;
    border-radius: 5px !important;
    display: flex;
}

.buysell-link:hover {
    border: 1px solid var(--border-buy) !important;
}

.buysell-link.active {
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.tab-trade-content {
    margin-top: 7px !important;
}

.main-input-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border: 1px solid var(--border-buy);
    padding: 2px 10px 4px 10px;
    border-radius: 5px;
    box-sizing: border-box;
}

.main-input-flex:hover {
    border-color: var(--primary-color);
}

.main-input-flex label {
    color: var(--input-color);
    font-size: 11px;
}

.main-input-flex input {
    background-color: transparent;
    border: 0px solid transparent;
    border-left: 2px solid #023e3e;
    border-radius: 0px;
    padding: 2px 10px;
    color: var(--text-color) !important;
    font-size: 14px !important;
}

.main-input-flex input:focus {
    background-color: transparent;
    border: 0px solid transparent;
    box-shadow: none;
    border-left: 2px solid #023e3e;
}

input[type=radio] {
    accent-color: var(--primary-color);
    margin-right: 10px;
    margin-top: 3px;
}

.input-image img {
    width: 20px;
}

.input-image span {
    color: var(--input-color);
    font-size: 14px;
}

.buy-btn {
    background: var(--color-success) !important;
    color: #000000 !important;
    padding: 7px 30px !important;

}

.sell-btn {
    background: var(--color-danger) !important;
    color: #000000 !important;
    padding: 7px 30px !important;

}

.buy-btn:hover {
    background-color: #00e6e6 !important;
    color: #000 !important;
}

.buy-d-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.buy-d-flex p{
    color: var(--text-color) !important;
    font-size: 12px;
    margin-bottom: 0px;
    
}

/* trading-view-chart */
.trading-view-chart {
    background: var(--section-bg);
    padding: 5px;
    border-radius: 10px;
    margin-top: 5px;
    width: 100%;
    height: 100%;
}

html.theme-dark #widget-container {
    border-color: var(--color-danger) !important;
}



::-webkit-scrollbar {
    width: 2px !important;
    height: 3px;
}

::-webkit-scrollbar-track {
    height: 2px;
    -webkit-box-shadow: inset 0 0 1px var(--primary-color);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    height: 2px;
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 3px var(--primary-color);
}



select.order-select {
    background: transparent;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 2px 5px;
    color: var(--input-color);
    font-size: 12px;
}


/* Change styles when select is focused */
.order-select:focus {
    border-color: blue;
    /* Change border color */
    box-shadow: 0 0 5px blue;
    /* Add box shadow */
}

.order-select option:checked {
    background-color: #00191e;
    /* Change background color of selected option */
    color: white;
    /* Change text color of selected option */
}


/* trade-mobile-css */

.mobile-trade-tab{
    display: none;
}


.mobile-trade-nav{
    border-bottom: 0px solid transparent !important;
    gap: 30px;
}
.mb-link{

}
.mb-link {
    background-color: var(--card-bg) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--border-buy) !important;
    color: var(--input-color) !important;
    font-size: 14px !important;
    padding: 10px 40px !important;
    border-radius: 5px !important;
    display: flex;
}
.mb-link:hover {
    border: 1px solid var(--border-buy) !important;
}
.mb-link.active {
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.tab-content-body{
    background-color:var(--background-color) !important;
}


.footer-trade-table{
    background-color: var(--section-bg);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 50px;
}


.ftt-nav{
    border-bottom: 0px solid transparent !important;
    gap: 0px;
}
.ftt-link {
    background-color: var(--card-bg) !important;
    color: var(--text-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
    color: var(--input-color) !important;
    font-size: 14px !important;
    padding: 10px 40px !important;
    border-radius: 0px !important;
    display: flex;
}
.ftt-link:hover {
    border: 1px solid transparent !important;
    border-bottom: 1px solid var(--primary-color) !important;
}
.ftt-link.active {
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.ftt-content-body{
    background-color:var(--background-color) !important;
}

@media (max-width: 767px) {
    .mobile-trade-tab{
        display: block;
        margin-top: 20px;
    }
    .trade-main{
        display: none;
    }
    .mobile-trade-nav{
        gap: 5px !important;
        padding: 0px 15px !important;
    }
    .mb-link{
        padding: 6px 10px !important;
        width: 100px ;
    }
    .buysell-link{
        padding: 6px 10px !important;
        width: 100px ;
    }
    .ftt-link{
        padding: 6px 10px !important;
        width: 90px ;
    }
    .footer-trade-table{
        padding: 5px !important ;
    }
    .ftt-content-body.p-3{
        padding: 00px !important;
    }
    .trade-m-table{
        margin-top: 10px !important;
    }
    .tab-content-body.p-3{
        padding: 5px !important;
        padding-top: 0px !important;
    }
}

.order-detail-card{
    width: 100%;
    margin-top: 30px;
    background: var(--section-bg);
    border-radius: 10px;
    padding: 20px;
    border: 1px solid var(--border-buy);
}
.order-detail-card li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-color);
    margin-top: 20px;
    font-size: 14px;
    border-bottom: 1px solid var(--border-c);
    padding: 0px 0px 10px 0px;
}

@media (max-width: 767px) {
    .order-detail-card{
        width: 100%;
    }
}























