:root {
    --background-color: #000 !important;
    --primary-color: #08d1d1;
    --secondary: #08d1d1;
    --btn-bg: #08d1d1;
    --text-color: var(--text-color);
    --header-bg: #000;

}

[data-theme="dark"] {
    --background-color: #000 !important;
    --primary-color: #08d1d1;
    --secondary: #08d1d1;
    --theme-bg: #000;

    --btn-bg: #08d1d1;
    --btn-bg-light: #08d1d1;
    --btn-hover: #08d1d1;
    --btn-border: #08d1d1;
    --text-primary: #08d1d1;
    --text-color: var(--text-color);
    --text-white: var(--text-color);
    --text-black: #000;
    --theme-text: #000;
    --color-success: #22bb33;
    --color-danger: #f94747;

    --header-bg: #00191e;
    --footer-bg: #00191e;
    --section-bg: #00191e;
    --card-bg: #000;
    --card-bg1: #00191e;

    --border-color: #08d1d1;

    --input-bg: #00191e;
    --input-border: #00191e;
    --input-color: #c4c4c4;


    --border-c: #202423;
    --border-buy: #023e3e;
    --table-hover: #000;



}

[data-theme="light"] {
    --background-color: var(--text-color) !important;
    --primary-color: #08d1d1;
    --secondary: #08d1d1;
    --theme-bg: var(--text-color);

    --btn-bg: #08d1d1;
    --btn-bg-light: #08d1d1;
    --btn-hover: #08d1d1;
    --btn-border: #08d1d1;

    --text-primary: #08d1d1;
    --text-color: #000;
    --text-white: var(--text-color);
    --text-black: #000;
    --theme-text: var(--text-color);
    --color-success: #22bb33;
    --color-danger: #f94747;


    --header-bg: #f7f7f7;
    --footer-bg: #f7f7f7;
    --section-bg: #f7f7f7;
    --card-bg: #e3e6e6;
    --card-bg1: #f7f7f7;

    --border-color: #08d1d1;

    --input-bg: #f7f7f7;
    --input-border: #f7f7f7;
    --input-color: #1c1717;

    --border-c: #dadada;
    --border-buy: #d3d3d3;
    --table-hover: #e8e8e8;
}



/* kyc-profile */
.kyc-page-tab {
    margin-bottom: 50px;
    margin-top: 50px;
}

.kyc-page-tab ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.kyc-page-tab ul li {
    list-style: none;
    position: relative;
    width: 25%;
    text-align: center;

}

.kyc-page-tab ul li a {
    color: var(--text-color);
    font-size: 25px;
    font-weight: bold;
    background: #000;
    border-radius: 100px;
    padding: 31px 40px;
    border: 2px dotted var(--primary-color);
    z-index: 9;
    box-sizing: border-box;
}

li.kyc-list-active a {
    background: var(--primary-color) !important;
    color: #000 !important;
}

.kyc-page-tab ul li a:hover {
    background: var(--primary-color) !important;
    color: #000;
}

.a-child1 {
    padding: 31px 43px !important;
}

.kyc-page-tab ul li a:after {
    content: '';
    background: var(--primary-color);
    width: 100%;
    height: 1px;
    position: absolute;
    top: 20px;
    left: 210px;
}

.a-last-child:after {
    display: none !important;
}

.li-div2 {
    margin-top: 50px;
}

.li-div2 h5 {
    font-size: 17px;
    color: var(--text-color);
}

.profile-sidebar-main {
    background: var(--section-bg);
    padding: 20px 20px;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    height: 500px;
}

.profile-main-div {
    background: var(--section-bg);
    padding: 20px 20px 50px 20px;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 30px;
    margin-top: 30px;

}

.pf-sidebar-header {
    text-align: center;
}

.pf-image img {
    width: 100px;
}

.pf-sidebar-header h5 {
    color: var(--text-color);
    margin-bottom: 10px;
    font-weight: 400;
}

.pf-sidebar-header p {
    color: var(--text-color);
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
}

.pf-sidebar-menu {}

.pf-sidebar-menu ul {
    padding: 0;
    margin: 20px 0px;
}

.pf-sidebar-menu ul li {
    display: block;
    margin-top: 2px;


}

.pf-sidebar-menu ul li:hover a {
    color: var(--primary-color);
    background: var(--card-bg1);

}

.pf-sidebar-menu ul li a {
    color: var(--text-color);
    display: flex;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 10px;
    border-radius: 5px;
}

.pf-sidebar-menu ul li .active {
    color: var(--primary-color);
    background: var(--card-bg);
}

.sidebar-span-dflex {
    display: flex;
    align-items: center;
}

.sidebar-user-icon1 {
    margin-right: 10px;
}

.user-page-main {}

.user-page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-page-title h5 {
    color: var(--text-color);
}

.user-page-title p {
    color: var(--text-color);
    font-size: 14px;
}

.status-div span {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
}

.show-status {
    background-color: var(--card-bg);
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 5px;
    color: var(--primary-color) !important;

}

.userdata-group label {
    color: var(--text-color);
    font-size: 13px;
    font-weight: 400;
    text-transform: capitalize;

}

.userdata-group span {
    margin-left: 10px;
    color: var(--text-color);
    font-size: 13px;
    font-weight: 400;
}

.userdata-group li {
    list-style: none;
    color: var(--text-color);
    font-size: 14px;
    background: var(--card-bg);
    padding: 10px 20px;
    border-radius: 5px;
    height: 43px;
}

.kycpage-header {
    padding: 30px 0px;
    border-bottom: 1px solid var(--border-buy);
}

.kyc-header-flex {
    display: flex;
    gap: 80px;
}

.kyc-header-flex p {
    color: var(--text-color);
}

.kycPage-body1 {
    background: var(--card-bg);
    padding: 50px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.kycPage-body1 li {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

}

.kp-body1 {
    display: flex;
    background: var(--primary-color);
    color: var(--text-black);
    padding: 10px;
    border-radius: 100px;
}

.kp-icon {
    font-size: 25px;
    font-weight: bold;
}

.kycPage-body1 h2 {
    color: var(--primary-color);
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
}

.kycPage-body1 a {
    color: var(--text-black);
    background: var(--primary-color);
    padding: 7px 30px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
}

.pt-header {
    background-color: var(--section-bg);
    padding: 5px 0px;
    display: none;
}

.pt-row {
    display: flex;
    justify-content: end;
}

.pt-btn {
    display: flex;
    align-items: center;
    background-color: var(--card-bg) !important;
}

div#offcanvasWithBothOptions1 {
    background: var(--section-bg) !important;
}

@media (max-width: 992px) {
    .pt-header {
        display: block;
    }

    .col-xl-dnone {
        display: none;
    }

    .profile-main-div {
        width:730px;
        padding-top: 0px !important;
    }

}


/*referral-page-css*/

.ref-page-title h3 {
    color: var(--text-color);
    font-size: 24px;
}

.ref-Link {
    margin-top: 40px;
    color: var(--text-color);
}

.form-list {
    width: 80%;
    position: relative;
}

.form-list-1 {
    width: 80%;
    position: relative;
}

.form-list label {
    font-size: 14px;
    font-weight: 500;
}

.ref-Link input {
    list-style: none;
    background-color: var(--card-bg);
    padding: 9px 10px;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 5px;
    color: var(--input-color);
    border: none;
}

.ref-Link input:focus {
    background-color: var(--card-bg);
    box-shadow: none;
    color: var(--input-color);
}

.ref-copt-btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.ref-copt-btn:hover {
    background-color: var(--primary-color) !important;
    color: var(--text-white) !important;
    z-index: 1;
}

.share-div {
    display: flex;
    align-items: end;
    gap: 10px;
    width: 70%;
}

@media (max-width:767px){
    .form-list {
        width:81%;
       position: relative;
    }
    .share-div {
        display: flex;
        width: 100%;
    }
}
@media (max-width:468px){
    .form-list {
        width: 100%;
       position: relative;
    }
    .form-list-1 {
        width: 100%;
       position: relative;
    }
}


.share-btn {
    width: 100px;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.share-btn:hover {
    background-color: var(--primary-color) !important;
    color: var(--text-white) !important;
    z-index: 1;
}

.ref-bonus-main {
    margin-top: 50px;
}

.ref-bonus-main h3 {
    color: var(--text-color);
    font-size: 20px;
}

.ref-table-main {
    margin-top: 30px;
}

.ref-table {
    background-color: var(--section-bg);
}

.ref-table,
td {
    background: transparent !important;
    color: var(--input-color) !important;

}

.ref-table thead tr {
    border-bottom: 0px solid var(--border-buy);
    font-size: 13px;
    font-weight: 400;
}

.ref-table thead tr th {
    padding: 15px 10px;
    font-size: 13px !important;
    font-weight: 500;
}

.ref-table tbody tr {
    border-bottom: 0px solid var(--card-bg1);
    background: var(--card-bg1);
}

.ref-table tbody tr:hover {
    background: var(--table-hover);
}

.ref-table tbody tr td {
    padding: 15px 3px !important;
    /* padding: 15px 10px !important; */
    font-size: 13px;
    font-weight: 500;
    color: var(--text-color);

}

.ref-bonus-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.ref-card {
    background-color: var(--card-bg) !important;
    padding: 20px !important;
    width: 33%;
    border-color: transparent !important;
    margin-top: 30px;
}

.ref-card h4 {
    color: var(--text-color);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.ref-card p {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
}

.userverify-card {
    background: var(--card-bg) !important;
    width: 600px;

    margin-top: 50px;
    padding: 50px 20px;
}

.userverify-card label {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
}

.userverify-card input {
    background: var(--input-bg) !important;
    border-color: var(--input-border) !important;
    padding: 10px 20px !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: 400;
    color: var(--input-color) !important;

}

.userverify-card input::placeholder {
    color: var(--input-color);
}

.userverify-card input:focus {
    background: #00191e;
    outline: none;
    box-shadow: none !important;
    border: 0px;
    color: #c4c4c4;
}

.show-popup {
    padding: 10px 20px;
    border-radius: 5px;
    /* animation: blink 1s linear infinite; */
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card-bg);
}

.show-popup h4 {
    text-transform: capitalize;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 400;
    color: var(--text-color);
    /* animation: blink 1s linear infinite; */
}

.verify-btn {
    color: var(--text-color);
}

@keyframes blink {

    0%,
    100% {
        color: lightcoral;
    }

    50% {
        color: #8EDDBE;
    }
}


.text-danger-glow {
    color: #ff4141;
    text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
    margin-right: 10px;
}

.blink {
    animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


.chart-main-div {
    background: var(--section-bg);
    padding: 20px;
    border-radius: 10px;
}





@media (max-width:576px) {
    .show-popup {
        flex-direction: column;
        gap: 20px;
        padding: 10px 10px;
    }

    .show-popup h4 {
        font-size: 13px;
    }

    .profile-main-div {
        width: auto !important;
        padding: 20px 10px;
    }

    .user-page-title h5 {
        font-size: 15px;
    }

    .user-page-title {
        margin-top: 20px;
        align-items: start;
    }

    .row.mt-5.mb-5.row-margin-20 {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .kycPage-body1 {
        padding: 20px;
    }

    .kycPage-body1 h2 {
        font-size: 17px;
    }

    .kyc-01-heading {
        padding: 0px;
        flex-direction: column;
        align-items: flex-start;
    }
    .kyc-01-heading h5{
        font-size: 16px;
    }
    .kyc-01-heading p{
        font-size: 14px;
    }
    .container-1{
        padding: 0px !important;
    }
    .kyc-inner-page {
        padding: 10px;
    }
    span.number {
        display: none;
    }
    .tab-form-main{
        padding: 0;
    }
    .form-inner-heading.mt-5 {
        margin-top: 12px !important;
        display: none;
    }
    .profile-image img {

        width: 100%;
    }
    .kyc-tab1{
        padding: 30px 0px 0px 0px !important;
    }
    video.webcam-preview {
        width: 100% !important;
    }
    .margin-0{
        margin-top: 0px !important;
    }
    .change-row{
        padding: 10px;
    }
    .tab-pane{
        padding: 0px !important;
        margin: 0px !important;
       max-width: 100% !important;
    }
    .userverify-card{
        width: 100% !important;
        padding: 10px 10px;
    }
}


.bug-report-form{
    padding: 30px 0px;
}

.bug-report-form label {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
  }
  
  .bug-report-form input ,textarea{
    background: var(--card-bg) !important;
    border-color: var(--input-border) !important;
    padding: 10px 20px !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: 400;
    color: var(--input-color) !important;
  
  }
  textarea{
    resize: none !important;
  }
  
  .bug-report-form input::placeholder {
    color:var(--input-color);
  }
  
  .bug-report-form input,textarea:focus {
    background: #00191e;
    outline: none;
    box-shadow: none !important;
    border: 0px;
    color: #c4c4c4;
  }
  .bug-column-2{
    padding: 60px 0px;
  }
  .bug-column-2 h5{
    font-size: 20px;
    color: var(--text-color);
  }
  .bug-column-2 p{
    font-size: 14px;
    color: var(--text-color);
  }

@media(max-width:992px){
    .tab-form-main{
    width: auto;
    /* margin: 0px auto;
    padding: 30px;
    border-radius: 10px; */
    }
}
@media(max-width:576px){
    .ref-bonus-main {
        margin-top: 20px;
    }

    .ref-bonus-card {
        display: flex;
        flex-wrap:wrap;
        gap: 8px;
    }

   .ref-card {
        padding: 17px !important;
        width: 48%;
    }
    .bug-column-2 {
        padding: 20px 0px;
    }

}

  
@media (max-width: 768px) {
    .navbar-toggler {
      display: none;
    }
  }
  

