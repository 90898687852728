:root {
  --background-color: #000 !important;
  --primary-color: #08d1d1;
  --secondary: #08d1d1;
  --btn-bg: #08d1d1;
  --text-color: #fff;
  --header-bg: #000;

}

[data-theme="dark"] {
  --background-color: #000 !important;
  --primary-color: #08d1d1;
  --secondary: #08d1d1;
  --theme-bg: #000;
  --bg-lightblack: #252525;

  --btn-bg: #08d1d1;
  --btn-bg-light: #08d1d1;
  --btn-hover: #08d1d1;
  --btn-border: #08d1d1;
  --text-primary: #08d1d1;
  --text-color: #fff;
  --text-white: #fff;
  --text-black: #000;
  --theme-text: #000;
  --color-success: #22bb33;
  --color-danger: #f94747;

  --header-bg: #00191e;
  --footer-bg: #00191e;
  --section-bg: #00191e;
  --card-bg: #000;
  --card-bg1: #00191e;

  --border-color: #08d1d1;

  --input-bg: #00191e;
  --input-border: #00191e;
  --input-color: #c4c4c4;


  --border-c: #202423;
  --border-buy: #023e3e;
  --table-hover: #000;



}

[data-theme="light"] {
  --background-color: #fff !important;
  --primary-color: #08d1d1;
  --secondary: #08d1d1;
  --theme-bg: #fff;
  --bg-lightblack: #fff;

  --btn-bg: #08d1d1;
  --btn-bg-light: #08d1d1;
  --btn-hover: #08d1d1;
  --btn-border: #08d1d1;

  --text-primary: #08d1d1;
  --text-color: #000;
  --text-white: #fff;
  --text-black: #000;
  --theme-text: #fff;
  --color-success: #22bb33;
  --color-danger: #f94747;


  --header-bg: #f7f7f7;
  --footer-bg: #f7f7f7;
  --section-bg: #f7f7f7;
  --card-bg: #e3e6e6;
  --card-bg1: #f7f7f7;

  --border-color: #08d1d1;

  --input-bg: #f7f7f7;
  --input-border: #f7f7f7;
  --input-color: #1c1717;

  --border-c: #dadada;
  --border-buy: #d3d3d3;
  --table-hover: #e8e8e8;
}


html {
  scroll-behavior: smooth;
}

body {
  background: var(--background-color) !important;
  font-family: "Outfit", sans-serif !important;
}

.container {
  max-width: 1320px !important;
}

@media (max-width: 576px) {
  .container {
    max-width: 90% !important;
    padding: 0px !important;
  }

}

.accordion-item {
  margin-bottom: 25px;
  border-radius: 10px !important;
  border: none !important;
}

button.accordion-button {
  border-radius: 10px !important;
  background-color: #000 !important;
  color: var(--text-color) !important;
  font-size: 20px;
  font-weight: 600;
  border: none !important;
}

.accordion-body {
  background-color: #00191ee0 !important;
  color: #fff !important;
  font-size: 16px;
  border: none !important;
}

.accordion-button:focus {
  box-shadow: 0px 0px 0px #000 !important;
}

.accordion-header {
  background-color: #00191e !important;
}

.accordion-item {
  margin-bottom: 25px;
  border-radius: 10px !important;
  border: none !important;
}

button.accordion-button {
  border-radius: 10px !important;
  background-color: var(--card-bg1) !important;
  color: var(--text-color) !important;
  font-size: 15px;
  font-weight: 600;
  border: none !important;
  border-radius: 0px !important;
}

.accordion-body {
  background-color: var(--card-bg) !important;
  color: var(--text-color) !important;
  font-size: 14px;
  font-weight: 400;
  border: none !important;
}

.accordion-button:focus {
  box-shadow: 0px 0px 0px #000 !important;
}

.accordion-header {
  background-color: #00191e !important;
}


@media (max-width: 767px) {
  .trading_form {
    display: block !important;
  }

  .trading_form_input {
    width: 100%;
  }

  .banner_heading {
    font-size: 36px;
  }

  .phon_m {
    margin-bottom: 17px;
  }

  .accordion-button {
    font-size: 16px !important;
  }

  .accordion-item {
    margin-bottom: 12px;
  }

  .accordion-body {
    font-size: 14px;
  }

}


.faq-row-flex {
  align-items: center;
}

.faq-mid-image {
  text-align: end;
}

.faq_nav_tab {
  justify-content: space-around;
  border: none !important;
}


.faq_tab_btn {
  background-color: var(--card-bg1) !important;
  color: var(--primary-color) !important;
  padding: 7px 23px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 4px;
  display: inline-block;
  border: none !important;
}

.faq_tab_btn:hover {
  background-color: var(--primary-color) !important;
  color: var(--text-black) !important;
}

.faq_tab_btn.nav-link.active {
  background-color: var(--primary-color) !important;
  color: var(--text-black) !important;
}

.faq-cc {
  background-color: var(--background-color);
  padding: 40px 0px;
}

.faq-iconbox {
  background-color: var(--background-color);
  padding: 40px 0px;
}

.earn01 {
  background-color: var(--card-bg);
  padding: 40px 0px;
}

.earn02 {
  background-color: #000;
  padding: 40px 0px;
}

.earn03 {
  background-color: #00191e;
  padding: 40px 0px;
}

@media (max-width: 767px) {
  .trading_form {
    display: block !important;
  }

  .trading_form_input {
    width: 100%;
  }

  .banner_heading {
    font-size: 23px;
  }
}

.trading_table {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.table_heading,
.table_data {
  padding: 10px 0px;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: #ffffff36;
}


.trading_form {
  gap: 17px;
  display: flex;
}

.trading_form_input {
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid #433939;
  background-color: #0000;
  color: #fff;
  font-size: 18px;
}

select:focus-visible {
  outline: 0px !important;
  border-color: #00e6e6 !important;
}

.option {
  background-color: #000;
  color: #fff;
  padding: 15px;
  display: inline-block;
}

.option:hover {
  background-color: #fff !important;
  color: #000 !important;
}


/* --------ajay---------- */

.banner_img_faq {
  width: 80%;
}
.policy_img_faq {
  width: 80%;
}

.icon_box-jaw5,
.icon_box-jaw6,
.icon_box-jaw7,
.icon_box-jaw8 {
  margin-top: 55px;

}

.icon_box-jaw-1 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.carre02 {
  padding: 40px 0px;
  background-color: #00191e;
}

.counter_box_main {
  padding: 20px;
  border: 2px solid #00e6e6;
  position: relative;
  border-radius: 10px;
}

.counter_box {
  background-color: #000;
  border-radius: 10px;
  padding: 20px;
}

.counter_title {
  font-size: 55px;
  font-weight: 800;
  margin-top: 25px;
}

.counter_text {
  font-size: 24px;
}

.counter_border {
  width: 8px;
  height: 126px;
  position: absolute;
  background-color: #00e6e6;
  bottom: -4px;
  left: -5px;
  border-radius: 10px 10px 0px 15px;
}

.counter_border2 {
  width: 126px;
  height: 8px;
  position: absolute;
  background-color: #00e6e6;
  bottom: -4px;
  left: -5px;
  border-radius: 10px 10px 10px 15px;
}

.counter_box_main:hover .counter_box {
  box-shadow: -1px -1px 15px #00e6e63d;
}

/* kyc  */

.kyc01 {
  padding: 40px 0px;
  background-color: #000;
}

.kyc_form label {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.kyc_form_input {
  background: #00191e !important;
  border-color: #00191e !important;
  padding: 10px 20px !important;
  border: 0px !important;
  font-size: 14px !important;
  font-weight: 400;
  color: #c4c4c4 !important;
  width: 100%;
  border-radius: 4px;
}

input:focus-visible {
  outline: 0px !important;
}

.kyc_sut_btn {
  background: #00e6e6 !important;
  color: #fff !important;
  width: 100%;
  padding: 6px;
  border-radius: 4px;
  border: none;
}

.kyc_form_file {
  background-color: #00191e;
  padding: 11px;
  border-radius: 4px;
  margin-top: 17px;
}

.kyc_form_img {
  width: auto;
}

/* -----about01----- */

.about01 {
  background-color: #000;
  padding: 40px 0px;
}

.about02 {
  padding: 60px 0px;
  background-color: #00191e;
}

.about_sec_02 {
  border: 0.5px solid #00e6e61f;
  border-radius: 10px;
  box-shadow: -1px -1px 15px #00e6e63d;
  padding: 40px;
}

.about-heading {
  font-size: 46px;
}

.about03 {
  background-color: #000;
  padding: 60px 0px;
}

.about_sec_img2 {
  height: 299px;
}
img.policy_banner_img.w-50{
  width: 80% !important;
}


@media (max-width:767px) {
  .about_banner_img {
    width: 80%;
  }

  .about_sec_img1 {
    width: 100%;
  }

  .about_sec_img2 {
    width: 100%;
    height: auto !important;
  }

  .about-heading {
    font-size: 34px;
    margin-top: 30px;
  }

  .about02 {
    padding: 40px 0px;
  }

  .about03 {
    padding: 40px 0px;
  }

  .about_sec_02 {
    padding: 20px;
  }

  .column-reverse {
    flex-direction: column-reverse;
  }

  .about_sec_img2 {
    margin-bottom: 30px;
  }

  .about_sec_img1 {
    margin-bottom: 30px;
  }

  .faq_nav_tab {
    row-gap: 14px;
  }

  .faq_tab_btn {
    font-size: 14px;
  }

  .faq_tab_btn {
    font-size: 14px;
  }
}

/* ----Security---- */

.security_01 {
  background-color: var(--background-color);
  padding: 40px 0px;
}

.security-heading {
  font-size: 46px;
}

@media (max-width:767px) {
  .security-heading {
    font-size: 34px;
  }

}

/* ---Downloads----- */

.download_sec01 {
  background-color: var(--background-color);
  padding: 40px 0px;
}

.download_sec01_heading {
  font-size: 46px;
}

.download_sec02 {
  background-color: var(--sec);
  padding: 40px 0px;
}

.download_sec02_box {
  border: 0.5px solid #00e6e61f;
  border-radius: 10px;
  box-shadow: -1px -1px 15px #00e6e63d;
  padding: 40px;
}

@media (max-width:767px) {
  .download_sec01_heading {
    font-size: 34px;
  }
}

/* -----ref----- */

.ref_01 {
  background-color: var(--background-color);
  padding: 40px 0px;
}

.ref_title {
  font-size: 33px;
  font-weight: bold;
}

.ref_btn:hover {
  background-color: #c8f4ff30;
  color: #00e6e6;
}

.ref_btn {
  background-color: #00e6e6;
  color: #000;
  margin-top: 20px;
  padding: 9px 34px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  display: inline-block;
}

.ref_02 {
  background-color: var(--section-bg);
  padding: 40px 0px;
}

.rewards_box {
  background-color: var(--card-bg);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  border-bottom: 2px solid #00e6e6;
}

.rewards_box:hover {
  box-shadow: 0px 0px 6px -3px var(--text-color);
}

.rewards_box_img {
  width: 200px;
  position: absolute;
  top: 0px;
  right: 20px;
}

.rewards_sub_title {
  font-size: 18px;
  font-weight: 600;
}

.usdt {
  font-size: 13px;
  margin-left: 5px;
}

.rewards_title {
  font-size: 20px;
  font-weight: 700;
}

.rewards_title2 {
  margin-top: 40px;
  font-size: 17px;
  font-weight: bold;
}

.ref_04 {
  background-color: var(--background-color);
  padding: 40px 0px;
}

.ref_counter_box {
  background-color: var(--card-bg1);
  border-radius: 10px;
  padding: 20px;
}

.counter_box_main:hover .ref_counter_box {
  box-shadow: 0 0px 6px -3px var(--text-color);
}

.ref_counter_box img {
  width: 200px;
}

.ref_counter_box img {
  width: 200px;
  height: 150px;
  object-fit: contain;
}


.ref_counter_title {
  font-size: 20px;
  font-weight: bold;
}

.ref_counter_text {
  font-size: 14px;
  font-weight: 600;
}

@media (max-width:992px) {
  .ref_title {
    font-size: 26px;
  }

  .rewards_box_img {
    width: 95px;
    position: absolute;
    top: 32px;
    right: 10px;
  }

  .rewards_sub_title {
    font-size: 15px;
  }

  .rewards_title2 {
    margin-top: 16px;
    font-size: 18px;
  }

  .counter_box_main {
    padding: 15px;
  }

  .ref_counter_box {
    padding: 10px;
  }

  .ref_counter_box img {
    width: 141px;
  }

  .ref_counter_title {
    font-size: 20px;
  }

}

@media (max-width:767px) {
  .ref_01{
    padding: 0px !important;
  }
  .ref-banner{
    gap: 30px !important;
    padding: 30px 0 0 0;
  }
  .ref_title {
    font-size: 24px;
  }

  .counter_box_main {
    margin-bottom: 25px;
  }

  .ref_btn {
    margin-top: 5px;
  }

  .rewards_title2 {
    margin-top: 20px;
    font-size: 17px;
  }

  .rewards_box_img {
    width: 140px;
    top: 20px;
  }

  .rewards_sub_title {
    font-size: 13px;
  }

  .rewards_box {
    margin-bottom: 20px;
  }

  .ref_counter_title {
    font-size: 18px;
  }

}

.banner_img_earn {
  width: 80%;
}

.fees01 {
  padding: 40px 0px;
  background-color: #000;
}

.fees01_img {
  height: 400px;
  object-fit: contain;
}

.fees02 {
  padding: 40px 0px;
  background-color: #00191e;
}

.fees_icon {
  font-size: 52px;
  margin-top: -4px;
  display: block;
  margin-right: 9px;
}


.user_1 {
  width: 70px;
  margin-right: 20px;
}

.icon_box_title {
  font-size: 22px;
}

.icon_box_text {
  font-size: 16px;
}

.link_text {
  font-size: 26px;
}

.border_col {
  border-right: 1px solid #525252;
}

.conin04 {
  padding: 40px 0px;
  /* background-color: #00191e; */
  background-color: #000;
}


.coin-input {
  background: #00191e !important;
  border-color: #00191e !important;
  padding: 10px 20px !important;
  border: 0px !important;
  font-size: 14px !important;
  font-weight: 400;
  color: #c4c4c4 !important;
}

.coin-lable {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: block;
  margin-top: 13px;
}

.coin_btn {
  background-color: #00e6e6;
  color: #000;
  margin-top: 20px;
  padding: 9px 34px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  display: inline-block;
  border: none !important;
}

@media (max-width:767px) {
  .fees01 {
    padding: 20px 0px 40px 0px;
    background-color: #000;
  }
  .faq-banner{
    margin-top: 50px;
  }

}

@media (max-width:400px) {
  .faq_nav_tab{
    flex-direction: column;
  }
}


.red {
  color: var(--color-danger) !important;
}

.green {
  color: var(--color-success) !important;
}

.logout-modal-content {
  background: var(--card-bg) !important;
  border-bottom: 1px solid var(--primary-color) !important;
}

.logout-modal-header {
  color: var(--text-color) !important;
  border-color: var(--border-c) !important;
}

.logout-modal-body p {
  color: var(--text-color) !important;

}

.logout-modal-footer {
  border-color: var(--border-c) !important;
}

.btn-cancel {
  background-color: transparent !important;
}

.ref_banner_image {
  width: 70% !important;
}


.accordion-button::after {
  display: none !important;
}

.faq01 {
  background-color: var(--bg-lightblack);
}


.security {
  animation: upDown 2s infinite;
}

@keyframes upDown {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
    /* This is how much it moves upward */
  }
}

.awap {
  color: white;
  margin-left: 50%;
}


img.w-10 {
  width: 60px;
}


.store{
  gap:18px;
}



/* .dropdown-nav {
	opacity: 0;
	transition: transform .3s cubic-bezier(0.88,-0.72, 0, 1), opacity .3s ease-in-out;
	transform: translateY(1rem);
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 1;
	background: rgba(225,255,225,.9);
	
}
.dropdown1:hover .dropdown-nav {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
} */

#tradingview_162b6-wrapper{
  background-color: var(--background-color) !important;
}


/* policy-page */
