:root {
    --background-color: #000 !important;
    --primary-color: #1BA4A4;
    --secondary: #1BA4A4;
    --btn-bg: #1BA4A4;
    --text-color: #fff;
    --header-bg: #000;
}

[data-theme="dark"] {
    --background-color: #000 !important;
    --primary-color: #08d1d1;
    --secondary: #08d1d1;
    --theme-bg: #000;

    --btn-bg: #08d1d1;
    --btn-bg-light: #08d1d1;
    --btn-hover: #08d1d1;
    --btn-border: #08d1d1;
    --text-primary: #08d1d1;
    --text-color: #fff;
    --text-white: #fff;
    --text-black: #000;
    --theme-text: #000;
    --color-success: #22bb33;
    --color-danger: #f94747;

    --header-bg: #00191e;
    --footer-bg: #00191e;
    --section-bg: #00191e;
    --card-bg: #000;
    --card-bg1: #00191e;

    --border-color: #08d1d1;

    --input-bg: #00191e;
    --input-border: #00191e;
    --input-color: #c4c4c4;


    --border-c: #202423;
    --border-buy: #023e3e;
    --table-hover: #000;



}

[data-theme="light"] {
    --background-color: #fff !important;
    --primary-color: #1BA4A4;
    --secondary: #1BA4A4;
    --theme-bg: #fff;

    --btn-bg: #1BA4A4;
    --btn-bg-light: #1BA4A4;
    --btn-hover: #1BA4A4;
    --btn-border: #1BA4A4;

    --text-primary: #1BA4A4;
    --text-color: #000;
    --text-white: #fff;
    --text-black: #000;
    --theme-text: #fff;
    --color-success: #22bb33;
    --color-danger: #f94747;


    --header-bg: #f7f7f7;
    --footer-bg: #f7f7f7;
    --section-bg: #f7f7f7;
    --card-bg: #e3e6e6;
    --card-bg1: #f7f7f7;

    --border-color: #1BA4A4;

    --input-bg: #f7f7f7;
    --input-border: #f7f7f7;
    --input-color: #1c1717;

    --border-c: #dadada;
    --border-buy: #d3d3d3;
    --table-hover: #e8e8e8;
}

.overview-header {
    background: var(--background-color);
    padding: 0px 0px;
}

.over-header-row {
    background: #00191e;
}

.overview-tab {
    border: 0px !important;
    align-items: center;
    gap: 30px;
    background: #00191e;
    padding: 7px 0px;
}

.overview-link {
    color: #fff !important;
    border: 0px solid transparent !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.overview-link:focus-visible {
    box-shadow: none;
}

.overview-link.active {
    background: transparent !important;
    color: #00e6e6 !important;
    border: 0px solid transparent !important;
}

/*tab-content*/
.overview-tab-content {
    padding: 70px 0;
}

.over-content-01 {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.over-pagetitle-01 {
    display: flex;
    align-items: center;
}

.over-pagetitle-01 h2 {
    color: var(--text-color);
    font-size: 24px;
    margin-bottom: 0px;

}

.over-pagetitle-01 span {
    background: var(--primary-color);
    margin-left: 10px;
    padding: 5px 8px;

    border-radius: 5px;
}

.over-01-icon {
    display: flex;

}

.d-gap {
    gap: 30px;
    margin-top: 30px;
}

.img-price-01 {
    gap: 10px;
    margin-top: 20px;
}

.img-price-01 img {
    width: 25px;
}

.img-price-01 span {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
}

.over-content-sec02 {
    padding: 70px 0px;
}

.swiper-card {
    padding: 15px;
    background: var(--card-bg1) !important;
    border: 1px solid transparent;
    z-index: 1 !important;
}

.swiper-card:hover {

    border: 1px solid var(--primary-color);
}

.s-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.s-card-header h5 {
    margin-bottom: 0;
    font-size: 16px;
    color: var(--text-color);

}

.s-card-header h5 span {
    font-size: 14px !important;
    color: var(--text-color);

}

.s-card-header p {
    margin-bottom: 0;
    font-size: 14px;
    color: var(--text-color);
}

.red-text {
    color: var(--color-danger);
}

.swiper-card h4 {
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 12px;
    color: var(--text-color);
}

.swiper-card h6 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 5px;
    color: var(--text-color);
}

/*card-2*/
.card-2 {
    padding: 30px 20px;
    background: var(--card-bg1) !important;
    border: 1px solid transparent;
    z-index: 1 !important;
    border-left: 1px solid var(--primary-color) !important;
    border-right: 1px solid var(--primary-color) !important;
    border-radius: 10px !important;
}

.card-2 li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-2 li img {
    width: 25px;
}

.card-2 li a {
    color: var(--text-color);
}

.right-icon {
    margin-left: 5px;
    color: var(--primary-color);
}

.card-2 h5 {
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
}

.card-2 button {
    padding: 6px 20px;
    background: var(--primary-color) !important;
}

.card-2 span {
    color: var(--primary-color);
}

/*Main Account*/
.over-01-innerdiv {
    display: flex;
    gap: 20px;
    align-items: center;
}

.over-01-innerdiv label {
    color: var(--text-color);
    font-size: 12px;
}

.main-account {
    margin-top: 50px;
}

.main-account-table {
    margin-top: 50px;
    background: #00191e;
    padding: 15px 15px;
    border-radius: 10px;
}

/* order.css*/

@media (max-width: 992px) {
    .over-content-01 {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        width: 100%;
    }

    .over-01-right {
        text-align: right !important;
        width: 100%;
    }

    .header-btn-main {
        justify-content: end;
    }
}


@media (max-width: 480px) {
    .over-01-right {
        text-align: right !important;
        width: 100%;
    }
    .login-btn1{
        padding: 7px 18px;
    }
    
    .header-btn-main {  
        gap:5px;    
        justify-content: center;
    }

}

@media (max-width: 700px) {
    .react-responsive-modal-modal{
        max-width: 300px !important;
        margin: 1.2rem;
        padding: 1.2rem;
    }

}
.text-center {
    text-align: center !important;
    justify-content: center;
    margin: auto;
}




/* swap */

.swap-main {
    padding-top: 70px;
    margin-bottom: 70px;

}

.swap-inner-col {
    background-color: var(--section-bg);
    border-radius: 10px;
    padding: 40px 20px;
}

.swap-inner-header {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    padding-bottom: 30px;

}

.swap-inner-header h3 {
    font-size: 18px;
    margin-bottom: 0px;
}

.swap-inner-header h3 {
    font-size: 16px;
    margin-bottom: 0px;
}

.swap-select.css-b62m3t-container {

    width: 120px !important;

}

.swap-sell-btn {
    padding: 10px 30px !important;
}




/* staking-page */
.staking-page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.staking-page-title h2 {
    color: var(--text-color);
    font-size: 22px;
    margin-bottom: 0;
    font-weight: 700;
}

.staking-page-title p {
    color: var(--text-color);
    font-size: 15px;
    margin-bottom: 0;
}

.stake-page-main {
    background-color: var(--background-color);
    padding: 50px 0px;
}

.staking-inner-column {
    background: var(--card-bg1);
    padding: 50px 20px;
    border-radius: 10px;
    margin-top: 20px;

}

.staking-inner-column label {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
}

.staking-inner-column input {
    background: var(--background-color) !important;
    border-color: var(--input-border) !important;
    padding: 10px 20px !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: 400;
    color: var(--input-color) !important;

}

.staking-inner-column input::placeholder {
    color: var(--input-color);
}

.staking-inner-column input:focus {
    background: #00191e;
    outline: none;
    box-shadow: none !important;
    border: 0px;
    color: #c4c4c4;
}

.staking-tabs{
    background-color: var(--section-bg);
    margin-top: 50px !important;
    padding: 20px;
    border-radius: 10px;
}
.staking-tab-ul{
    border-bottom: 0px solid transparent !important;
    gap:20px;
}

.staking-nav-link {
    background-color: var(--card-bg) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--primary-color) !important;
    color: var(--input-color) !important;
    font-size: 14px !important;
    padding: 10px 40px !important;
    border-radius: 10px !important;
    display: flex;
}
.staking-nav-link:hover {
    background-color: var(--primary-color) !important;
    color: var(--text-black) !important;
    border: 1px solid var(--primary-color) !important;
}
.staking-nav-link.active {
    background-color: var(--primary-color) !important;
    color: var(--text-black) !important;
    border: 1px solid var(--primary-color) !important;
}

@media (max-width: 767px){
    .mt-dropdown-body{
        right:0;
    }
}
@media (max-width: 576px){
    .tm-right-value1-red {
        display: none;
    }
}




/* ModalAnimation.css */
@-webkit-keyframes custome-animate {
    0% {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
        transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
        opacity: 0;
    }
    68% {
        -webkit-transform: rotateY(10deg) rotateX(10deg) translateZ(20px);
        transform: rotateY(10deg) rotateX(10deg) translateZ(20px);
        opacity: 0.8;                
    }
    100% {
        -webkit-transform: rotateY(0deg) rotateX(0deg) translateZ(0px);
        transform: rotateY(0deg) rotateX(0deg) translateZ(0px);
        opacity: 1;
    }
}
@keyframes custome-animate {
    0% {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
        transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
        opacity: 0;
    }
    68% {
        -webkit-transform: rotateY(10deg) rotateX(10deg) translateZ(20px);
        transform: rotateY(10deg) rotateX(10deg) translateZ(20px);
        opacity: 0.8;                
    }
    100% {
        -webkit-transform: rotateY(0deg) rotateX(0deg) translateZ(0px);
        transform: rotateY(0deg) rotateX(0deg) translateZ(0px);
        opacity: 1;
    }
}
.custome-animate {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: custome-animate;
    animation-name: custome-animate;
}

.close-button-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background-color: transparent !important;
    border: 1px solid var(--primary-color) !important;
    color:var(--primary-color) !important;
    cursor: pointer;
    padding: 0;
    z-index: 1050;
}


.popup-modal-body{
    background-color: var(--section-bg) !important;
    border-radius: 5px !important;
    overflow: hidden;
    border: .5px solid var(--primary-color);
}
.pop-header{
    border-bottom: 1px solid var(--border-c);
}
.pop-header h3{
        color: var(--text-color);
}
.pop-inner-body{
    padding: 20px 0px;
    color: var(--text-color);
}
.pop-inner-body p:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
}
.popup-kyc-btn{
    border-top: 1px solid var(--border-c);
    padding: 20px 0px;
}
.popup-kyc-btn1{
    padding: 10px 30px !important;
}
.token-history-main h4{
    color: var(--text-color);
}

@media (max-width:576px){
    .over-content-sec02{
        padding: 35px 0px;
    }
}

@media(max-width:767px){
    .ref-table-main {
        overflow-x: scroll;
    }
}

@media(max-width:407px){
    .div#nav-tab{
        display: flex !important;
        flex-wrap: nowrap !important;
    }
    .staking-nav-link{
        padding: 10px 20px !important;
    }
    .staking-tab-ul{
        gap: 52px !important;
    }
    .address-div li{
        padding: 10px 15px !important;
        width: 84%;
        overflow-x: scroll;
        /* overflow: hidden; */
    }
    .address-div span {
        position: absolute;
        right: 5px;
        bottom: 5px;
        border: 1px solid var(--primary-color);
        display: flex;
        padding: 7px 10px;
        border-radius: 5px;
        cursor: pointer;
        height: 80%;
        color: #00e6e6;
    }
}


@media(max-width:767px){
    .faq-iconbox{
        margin-top:-30px !important;
    }
        .margin_top{
        margin-top: 70px !important;
    }
}



@media(max-width:767px){
    .faq-row-flex{
        gap: 50px;
    }
}




