:root {
  --background-color:#000 !important;
  --primary-color:#08d1d1;
  --secondary:#08d1d1;
  --btn-bg:#08d1d1;
  --text-color:#fff;
  --header-bg:#000;

}

[data-theme="dark"] {
  --background-color:#000 !important;
  --primary-color:#08d1d1;
  --secondary:#08d1d1;
  --theme-bg:#000;

  --btn-bg:#08d1d1;
  --btn-bg-light:#08d1d1;
  --btn-hover:#08d1d1;
  --btn-border:#08d1d1;
  --text-primary:#08d1d1;
  --text-color:#fff;
  --text-white:#fff;
  --text-black:#000;
  --theme-text:#000;
  --color-success:#22bb33;
  --color-danger:#f94747;

  --header-bg:#00191e;
  --footer-bg:#00191e;
  --section-bg:#00191e;
  --card-bg:#000;
  --card-bg1:#00191e;

  --border-color:#08d1d1;

  --input-bg:#00191e;
  --input-border:#00191e;
  --input-color:#c4c4c4;


  --border-c:#202423;
  --border-buy:#023e3e;
  --table-hover:#000;
  


}

[data-theme="light"] {
  --background-color:#fff !important;
  --primary-color:#08d1d1;
  --secondary:#08d1d1;
  --theme-bg:#fff;

  --btn-bg:#08d1d1;
  --btn-bg-light:#08d1d1;
  --btn-hover:#08d1d1;
  --btn-border:#08d1d1;

  --text-primary:#08d1d1;
  --text-color:#000;
  --text-white:#fff;
  --text-black:#000;
  --theme-text:#fff;
  --color-success:#22bb33;
  --color-danger:#f94747;


  --header-bg:#f7f7f7;
  --footer-bg:#f7f7f7;
  --section-bg:#f7f7f7;
  --card-bg:#e3e6e6;
  --card-bg1:#f7f7f7;

  --border-color:#08d1d1;

  --input-bg:#f7f7f7;
  --input-border:#f7f7f7;
  --input-color:#1c1717;

  --border-c:#dadada;
  --border-buy:#d3d3d3;
  --table-hover:#e8e8e8;
}



.sec1 {}


.login-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, var(--primary-color) 40%, var(--theme-bg) 36%);
  height: 100vh;
}
/* .login-row-signup{
  height: 100% !important;
} */

.login-column-div1 {
  /* background-color: #00e6e6; */
  width: 40%;
  /* height: 100vh; */

}

.login-image-div {
  display: flex;
  align-items: center;
  /* height: 100vh; */
  justify-content: center;
}


.login-image-div img {
  width: 80%;
}
.forget-image-div{
  display: flex;
  align-items: center;
  /* height: 100vh; */
  justify-content: center;
}

.forget-image-div img {
  width: 100%;
}
.signup-image-div{
  display: flex;
  align-items: center;
  /* height: 100vh; */
  justify-content: center;
}
.signup-image-div img {
  width: 100%;
}
.login-column-div2 {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;

}
.login-page-title{
  display: block;
  padding-bottom: 30px;
}
.login-page-title h4{
  color: var(--primary-color);
}

.form-div {
  width: 60%;
}

.login-nav-tabs {
  border: 0px solid #fff !important;
}


.login-form label {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
}

.login-form input {
  background: var(--input-bg) !important;
  border-color: var(--input-border) !important;
  padding: 10px 20px !important;
  border: 0px !important;
  font-size: 14px !important;
  font-weight: 400;
  color: var(--input-color) !important;

}

.login-form input::placeholder {
  color:var(--input-color);
}

.login-form input:focus {
  background: #00191e;
  outline: none;
  box-shadow: none !important;
  border: 0px;
  color: #c4c4c4;
}

.otp-btn {
  background: var(--primary-color) !important;
  color: #fff !important;
  border-color: #00191e;
  padding: 7px 12px !important;
  width: 120px;
}

.email-flex-div1 {
  display: flex;
  align-items: end;
  gap: 10px;

}

.react-tel-input {
  width: 100% !important;

}

.react-tel-input .form-control {
  padding: 10px 20px 10px 60px !important;
  color: #c4c4c4 !important;
}

.password-div {
  position: relative;
}

.eye-icon-div {
  position: absolute;
  right: 10px;
  bottom: 12px;
}

.eye-icon {
  color: var(--primary-color);
}

input[type=checkbox] {
  width: 15px;
  height: 15px;
  accent-color: var(--primary-color);
  color: #c4c4c4;
}

input[type=checkbox]:checked {
  accent-color: var(--primary-color);
}

.login-btn {
  background: var(--primary-color)  !important;
  color: var(--text-black) !important;
  width: 100%;
}

.forgot-div span a {
  color: var(--text-color);
}


/*tab*/
a.navlink-1 {
  font-size: 15px !important;
  line-height: 0px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 0px;

}

a.navlink-1.active {
  background: transparent !important;
  color: var(--primary-color) !important;
  /* / border-color: ; / */
  border-left: 1px solid var(--primary-color) ;
  border-right: 1px solid var(--primary-color) ;
  border-radius: 0px;
}

.checkbox-d1 {
  display: flex;
  align-items: center;
}

.form-footer p {
  color: var(--text-color);
}

.form-footer a {
  color: var(--primary-color) ;
  text-decoration: none;
}


/*kyc-page*/
.kyc-01 {
  padding: 30px 0px;
}

.kyc-inner-row {
  width: 600px;
  margin: 0px auto;
  margin-top: 50px;
}

.kyc-page-title {
  color: var(--text-color);
}

.kyc-page-title h3 {
  font-size: 26px;
  font-weight: bold;
}

.kyc-page-title p {
  font-size: 16px;
  font-weight: 400;
}

.kyc-inner-page {
  background: var(--card-bg);
  padding: 30px 30px;
  border-radius: 20px;
}

.kyc-inner-page input {
  background: var(--input-bg) !important;

  padding: 10px 20px !important;
  border: 0.5px solid var(--input-border) !important;
  font-size: 14px !important;
  font-weight: 400;
  color: var(--input-color) !important;

}

.kyc-inner-page label {
  color: var(--text-color);
}

.kyc-inner-page input::placeholder {
  color: var(--input-color) !important;
}

.kyc-inner-page input:focus {
  background:var(--input-bg);
  outline: none;
  box-shadow: none;
  border: 0px;
  color: var(--input-color) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color:var(--input-color);
  transition: var(--input-bg) 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 50px var(--input-bg) inset !important;
  caret-color: #000;
  background: var(--input-bg) !important;

}

input::placeholder {
  color: var(--input-color) !important;
}

input[data-autocompleted] {
  background-color: transparent !important;
}


.kyc-btn {
  background: var(--btn-bg) !important;
  color: var(--text-black) !important;
  width: 50%;
}

/*kyc-row*/
.kyc-row {
  padding: 30px 0px;
  background: var(--section-bg);
  border-radius: 20px;
  width: auto;
  margin: 0px auto;
}

.title-padding {
  padding: 30px;
}

.kyc-01-heading {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0px 30px 30px 30px;
  border-bottom: 1px solid var(--border-c);
  color: var(--text-color);
}

.number {
  background: var(--primary-color);
  padding: 10px 13px;
  color: var(--text-black);
  border-radius: 100px;
}

.kyc-tab-main {
  padding: 0px 0px;
}

.kyc-tab-main p {
  color: var(--text-color);
  padding-left: 30px;
}

.kyc-tab1 {
  padding: 30px 30px 0px 0px !important;
  border-bottom: 0px !important;
  align-items: center;
  gap: 10px;

}

.kyc-nav-item {}

.kyc-nav-link {
  color: var(--text-color) !important;
  border: 1px solid var(--primary-color) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  background: transparent !important;
  border-radius: 5px !important;
}

.kyc-nav-link.active {
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 5px !important;

}

.kyc-nav-link:focus-visible {
  box-shadow: none;
}

.tab-form-main {
  margin-top: 30px;
  width: 600px;
  margin: 0px auto;
 
  padding: 30px;
  border-radius: 10px;
}

.tab-form-main input {
  background: var(--input-bg) !important;
  padding: 10px 20px !important;
  border: 0.5px solid var(--border-c) !important;
  font-size: 14px !important;
  font-weight: 400;
  color: var(--input-color);
}

.tab-form-main label {
  color: var(--text-color);
  text-transform: capitalize;
}

.tab-form-main input:placeholder {
  color: var(--input-color) !important;
}

.tab-form-main input:focus {
  background: var(--input-bg);
  outline: none;
  box-shadow: none;
  border: 0px;
  color: var(--input-color)!important;
}

.flex-kycdiv {
  display: flex;
  align-items: end;
}

.btn_upload {
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 5px !important;
  color: var(--text-black) !important;
  padding: 5px 15px;
  font-size: 14px !important;
}

.profile-image img {
  width: 200px;
  height: 150px;
}

.next-btn {
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 5px !important;
  color: #000;
  padding: 6px 30px;
  width: 100%;
}

.tab-show-bottom {
  border-bottom: 1px solid #023e3e;
  padding-bottom: 50px !important;

}

.tab-div-show1 {
  padding: 0px 30px;
}

.tab-div-show2 {
  visibility: visible !important;
}

.new-div2 {
  display: block !important;


}

.next-row1 {
  align-items: center;
}

.profile-image1 img {
  width: 100%;
}

.next-btn1 {
  width: 100% !important;
}

.pan-image {
  width: 100% !important;
}

.cange-pass-main {
  padding: 60px 0px;
}

.change-row {
  width: 600px;
  margin: 0px auto;
  background: var(--card-bg);
  padding: 30px;
  border-radius: 20px;
}

.reset-page-title {
  padding: 0px 0px 30px;
}

.reset-page-title h3 {
  color: var(--text-color);
}

.formdiv-1 {
  width: 100%;
}

.react-responsive-modal-modal {
  background: var(--card-bg1) !important;
  width: 600px !important;
  border-radius: 10px;
  padding: 30px 20px;
}

button.react-responsive-modal-closeButton svg {
  fill: var(--primary-color) !important;
}

.modal-header {
  margin-top: 30px;
}

.modal-header h3 {
  color: var(--text-color);
  font-size: 18px;
}

.modal-body1 {
  padding: 30px 0px 100px;
}

.modal-body1 label {
  color: var(--text-color);
  font-size: 14px;
}

.span-icon {
  background: var(--primary-color);
  padding: 13px 15px;
  border-radius: 10px;
  cursor: pointer;
}

.updown-icon {
  font-size: 20px;
}

.modal-body1 p {
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 0;
}

.model-input {
  background: var(--input-bg) !important;
  padding: 7px 10px !important;
  border: 1px solid #023e3e !important;
  font-size: 14px !important;
  font-weight: 400;
  color: var(--input-color) !important;
}

.model-input:focus {
  background: #00191e;
  outline: none !important;
  box-shadow: none !important;
  border: 0px;
  color: var(--input-color);
}

.modal-submit-btn {
  background: var(--btn-bg) !important;
  color: var(--text-black) !important;
  border-color: #00191e;
  padding: 7px 12px !important;
  width: 50%;
}

.form-inner-heading {
  display: flex;
  align-items: center;
  gap: 10px;

}

.form-inner-heading span {
  background-color: var(--primary-color);
  padding: 5px 10px;
  border-radius: 10px;
}

.drop-ic {
  color: var(--text-black);
  font-size: 17px;
}

.form-inner-heading h4 {
  color: var(--text-color);
  text-transform: capitalize;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 500;
}

@media (max-width:992px) {
  .form-div {
    width: 85%;
  }

  .kyc-row {
    width: 100%;
  }
  .change-row{
    width: auto;
  } 
}

@media (max-width:767px) {
  .login-row {
    flex-direction: column;
    background: linear-gradient(180deg, #00e6e6 0%, #000 0%);
    
  }

  .login-column-div2 {
    width: 100%;
  }

  .login-column-div1 {
    width: 100%;
    display: none;
  }

  .login-image-div img {
    width: 400px;
  }

  .otp-btn {
    width: 100px;
  }


  .tab-form-main {
    width: 100%;
  }

  .kyc-inner-row {
    width: 100%;
  }

  .kyc-inner-page {
    padding: 20px;
  }
}

@media (max-width:576px) {
  .email-flex-div1{
    
  }
}
@media (max-width:400px) {
  .login-image-div img {
    width: 60%;
  }
  .forget-image-div img {
    width: 61%;
}

  .form-div {
    width: 100%;
  }

  .login-column-div2 {
    width: 100%;
    padding: 30px 15px;
  }
}

ul.country-list {
  background-color: #00e6e6 !important;
}

/* axtra css */
input#react-select-3-input {
  
}
input#react-select-3-input {
  /* min-width: 0px !important; */
  width: 0px !important;
  background: transparent !important;
  padding:10px 0px !important;
  margin: 0px;
  padding: 0px 20px;
}

.css-qbdosj-Input {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1 / 2 / 2 / 3;
  grid-template-columns:0px !important;
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  box-sizing: border-box;
}

/* .css-19bb58m {
  grid-area: auto !important;
  grid-template-columns: auto !important;
} */

.css-qbdosj-Input:after{
  border-radius: 4px;
  padding:0 20px !important;
  border: 0px solid #000 !important; 
}

.c-select__value-container.css-1fdsijx-ValueContainer {
  padding: 0  20px !important;
  margin: 0px !important;

}
.c-select__input-container.css-qbdosj-Input {
  overflow: hidden !important;
  margin: 0px !important;
  padding: 0px 0px !important;
}

.selfie-image{
  width: 300px !important;
}


.c-select__single-value.css-1dimb5e-singleValue {
  margin: 0px;
  padding: 0 4px;
}


::placeholder {
  color: #c4c4c4 !important;
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.button-container {

  display: flex;
  justify-content: center;
  align-items: center;
}

.or-text {
  color: var(--text-color);
  margin: 0 10px; 
}

