:root {
  --background-color:#000 !important;
  --primary-color:#08d1d1;
  --secondary:#08d1d1;
  --btn-bg:#08d1d1;
  --text-color:var(--text-color);
  --header-bg:#000;
}

[data-theme="dark"] {
  --background-color:#000 !important;
  --bg-lightblack:#252525;
  --primary-color:#08d1d1;
  --secondary:#08d1d1;
  --theme-bg:#000;

  --btn-bg:#08d1d1;
  --btn-bg-light:#08d1d1;
  --btn-hover:#08d1d1;
  --btn-border:#08d1d1;
  --text-primary:#08d1d1;
  --text-color:var(--text-color);
  --text-white:var(--text-color);
  --text-black:#000;
  --theme-text:#000;
  --color-success:#22bb33;
  --color-danger:#f94747;

  --header-bg:#00191e;
  --footer-bg:#00191e;
  --section-bg:#00191e;
  --card-bg:#000;
  --card-bg1:#00191e;

  --border-color:#08d1d1;

  --input-bg:#00191e;
  --input-border:#00191e;
  --input-color:#c4c4c4;


  --border-c:#202423;
  --border-buy:#023e3e;
  --table-hover:#000;
  --primary-btn-hover:#e4faff30;
  


}

[data-theme="light"] {
  --background-color:var(--text-color) !important;
  --bg-lightblack:#fff;
  --primary-color:#08d1d1;
  --secondary:#08d1d1;
  --theme-bg:var(--text-color);

  --btn-bg:#08d1d1;
  --btn-bg-light:#08d1d1;
  --btn-hover:#08d1d1;
  --btn-border:#08d1d1;

  --text-primary:#08d1d1;
  --text-color:#000;
  --text-white:var(--text-color);
  --text-black:#000;
  --theme-text:var(--text-color);
  --color-success:#22bb33;
  --color-danger:#f94747;


  --header-bg:#f7f7f7;
  --footer-bg:#f7f7f7;
  --section-bg:#f7f7f7;
  --card-bg:#e3e6e6;
  --card-bg1:#f7f7f7;

  --border-color:#08d1d1;

  --input-bg:#f7f7f7;
  --input-border:#f7f7f7;
  --input-color:#1c1717;

  --border-c:#dadada;
  --border-buy:#d3d3d3;
  --table-hover:#e8e8e8;
  --primary-btn-hover:#38707f30;
}

/*mobile-header*/
.mobile-toggle{
  display: none;

}
.mobile-toggle-flex{
  display: flex;
  gap: 10px;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .navbar-toggler-dnone{
    display: none;
  }
  .mobile-toggle{
    display: block;
  }
}

.mobile-toggle-btn {
  color: var(--text-color) !important;
}

div#offcanvasWithBothOptions {
  background-color: var(--section-bg);
}
.dropdown-btn{
    padding: 0px !important;
    color: var(--text-color) !important;
}
.mobile-dropdown{
  position: relative;
}
.mobile-dropdown-body{
  visibility: hidden;
  opacity: 0;
  margin-top: 10px;
  background: #000;
  padding: 20px;
  position: absolute;
  width: 100%;
  top:25px;
  height: 0%;
  z-index: 999;
  transition: .2s;
  border: 1px solid var(--primary-color);
  border-top: 0px solid var(--primary-color);
}
.mobile-body-show{
 visibility: visible;
 opacity: 1;
 height:auto !important;
 border-radius: 0px 0px 10px 10px;

}
.mobile-logo {
  width: 100px;
}
.mobile-menu {}

.mobile-menu ul {
  padding: 0;
  margin: 0;
}
.mobile-footer{
  display: flex;
  margin-top: 30px;
  gap: 20px;
}

.mobile-menu ul li {
  list-style: none;
  margin-top: 20px;
}
.mobile-menu ul li:first-child{
  margin-top: 0;
}
.mobile-menu ul li a{
  color: var(--text-color);
  font-size: 14px;
}
.mobile-menu ul li a:hover{
  color: var(--primary-color);
}

.btn-close{
  --bs-btn-close-bg: url(../icons/close-bg.png)!important;
}

.accordion-item-userheader{

}
button.accordion-button-h1{
  background: var(--section-bg) !important;
  padding: 0;
  font-size: 14px !important;
  font-weight: 400;
  margin-top: 20px;
  border: none !important;
  box-shadow: 0px transparent !important;
  color: var(--text-color) !important;
  border-radius: 0px !important;
}

button.accordion-button.collapsed.accordion-button-h1 {
    background: var(--section-bg) !important;
    padding: 0;
    font-size: 14px !important;
    font-weight: 400;
    margin-top: 20px;
}
.accordion-button:not(.collapsed) {
  box-shadow: 0px 0px transparent !important;
}

.accordion-body.accordion-body-h1 {
  background: var(--section-bg) !important;
  margin-top: 12px;
  padding: 0;
}
.accordion-item-userheader{
  background: var(--section-bg) !important;
  color: var(--text-color) !important;
}

a {
  text-decoration: none !important;
}


a.nav-link {
  color: var(--text-color) !important;
  font-size: 14px;
  font-weight: 500;
}

.navbar-1 {
  padding: 0 !important;
}

.activeLink a.nav-link {
  color: var(--primary-color) !important;
}

.activeLink::after {
  width: 100%;
}

.header-logo {
  position: relative;
}

.logo-span img {
  width: 100px;
}

.logo-span:after {
  content: '';
  position: absolute;
  width: .5px;
  height: 40px;
  background-color: #aeb1bf80;
  right: -20px;
  top: 5px;
}

.top-header {
  background-color: var(--header-bg);
  box-shadow:  0px 0px 8px -5px var(--text-color);
  /* border-bottom: .3px solid #aeb1bf80; */
  padding: 0px 0px;
  transition: top 0.3s ease;
}

.fixed-header {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

nav.navbar.navbar-expand-md {
  padding: 0;
}

.navbar-collapse1 {
  display: flex;
  justify-content: space-between;
}

.header-ul {
  /* width: 70%; */
  text-align: center;
  gap: 25px;
  margin-left: 40px;
}

.h-nav-link {
  position: relative;
  padding: 20px 0px;
}

.h-nav-link::after {
  content: '';
  position: absolute;
  background: var(--primary-color);
  width: 0%;
  height: 2px;
  left: 0;
  bottom: 0;
  transition: 0.3s;
}

.h-nav-link:hover::after {
  width: 100%;
}

/* .h-dropdown */
.h-nav-link-dropdown {
  position: relative;
  padding: 20px 0px;
}
.user-header-dropdown{
  padding: 28px 20px !important;
}

.h-drop-icon {
  font-size: 20px;
}

.h-nav-link-dropdown:hover .h-dropdown-menu {
  visibility: visible;
  top: 58px;
  opacity: 1;
  transition: .3s;
}

.h-dropdown {
  position: relative;

}

.h-dropdown-menu {
  position: absolute;
  min-width: 200px;
  top: 80px;
  left: -30px;
  background-color: var(--card-bg1);
  border-radius: 10px;
  box-shadow: 0px 1px 8px -4px var(--text-color);
  visibility: hidden;
  transition: .0s;
  overflow: hidden;
  opacity: 0;
  z-index: 9;
}
.h-dropdown-menu.user-dropdown-menu{
  width: 255px !important;
}
@media (max-width:991px) {
  .h-dropdown-menu.user-dropdown-menu{
    width: 232px !important;
}

.h-dropdown-menu ul li{
  list-style: none !important;
  padding: 10px 10px !important;
}
}

.user-dropdown-menu{
  left: -72px !important;

}
.h-nav-link-dropdown:hover .user-dropdown-menu {
    top:50px !important;
}

.h-dropdown-menu ul {
  text-align: left;
  padding: 0px;
}

.h-dropdown-menu ul li {
  list-style: none;
  padding: 10px 20px;


}

.h-dropdown-menu ul li:hover {
  background: var(--table-hover);
}

.h-dropdown-menu ul li a {
  color: var(--text-color);
  display: flex;
}

.h-dropdown-menu ul li a:hover {
  color: var(--primary-color);
}

.header-right-div {
  gap: 30px;
  /* width: 30%; */
  justify-content: end;
  align-items: center;
}


.theme-btn {
  position: relative;
}

.mode-btn {
  color: var(--text-color) !important;
  cursor: pointer;
  animation: movesun 4s;

}


.icon-light {
  font-size: 22px;
}

.move-sun-icon {
  animation: movesun 4s linear infinite;
}

@keyframes movesun {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mode-btn:hover {

  color: var(--primary-color) !important;
}

.header-btn-main {
  display: flex;
  gap: 20px;
}

.btn-1 {
  background: var(--btn-bg)!important;
  padding: 7px 30px;
  border-radius: 5px;
  text-decoration: none;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid var(--btn-border);
}

.login-btn1 {
  background-color: var(--header-bg) !important;
  color: var(--primary-color);
  border: 1px solid var(--btn-border);
}

.login-btn1:hover {
  background-color: var(--btn-bg) !important;
  color: var(--text-black);
}

.bars {
  color: var(--text-color) !important;
}



@media (max-width: 576px) {
  .navbar-collapse1 {
    flex-direction: column;
  }

  .h-nav-link {

    padding: 0px 0px;
  }

  .header-ul {
    gap: 10px;
  }

  .header-right-div {
    justify-content: start;
  }
}

.user-profile{
  display: flex;
  align-items: center;
  gap: 10px;
}
.user-profile li{
  list-style: none;
  color: var(--text-color);
}
.user-profile li span{
  font-size: 12px;
  margin: 0px;
}
.user-icon-h1{
  color: var(--text-color) !important;
   font-size: 20px;
}

.drop-d-flex1{
  display: flex;
  align-items: center;
}

.d-icon{
  margin-right: 10px;
  font-size: 20px;
}

/*footer */

.footer {
  background: var(--footer-bg);
  padding: 70px 0px 50px 0;
}

.footer-logo img {
  width: 140px;
}

.footer-row {
  display: flex;
  border-bottom: .3px solid #aeb1bf80;
  padding-bottom: 50px;
}

.footer-column1 {
  width: 30%;
}

.footer-column1 p {
  color: #6c7080;
  font-size: 14px;
  margin-top: 30px;
  padding: 0px 150px 0px 0px;
}

.social-icon {
  display: flex;
  gap: 15px;
}

.social-icon a {
  color: var(--text-color);
}

.social-icon a:hover {
  color: var(--primary-color);
}

.footer-column2 {
  width: 15%;
}

.footer-heading h4 {
  color: var(--text-color);
  font-size: 14px;
}

.footer-ul {
  padding: 0;
  margin: 30px 0 0 0;
}

.footer-ul li {
  display: block;
  margin-top: 15px;
}

.footer-ul li:first-child {
  margin-top: 0px;
}

.footer-ul li a {
  color: #9295a6 !important;
  font-size: 13.5px !important;
  transition: .4s;
}

.footer-ul li a:hover {
  color: var(--primary-color) !important;
  margin-left: 10px;
}

.footer-bottom {
  padding: 15px 0px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-right p {
  color: #6c7080;
  font-size: 14px;
}

.footer-right img {
  width: 30px;
}

.card-icon {
  display: flex;
  gap: 20px;
}

.footer-left p {
  color: #6c7080;
  font-size: 14px;
}

@media (max-width: 992px) {
  .footer-row {
    flex-wrap: wrap;
    gap: 20px;
  }

  .footer-column1 {
    width: 30%;

  }

  .footer-column2 {
    width: 30%;

  }

  .footer-column1 p {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .footer-row {
    flex-wrap: wrap;
    gap: 40px;
  }

  .footer-column1 {
    width: 100%;

  }

  .footer-column2 {
    width: 42%;

  }

  .footer-column1 p {
    padding: 0;
  }

  .footer-bottom {
    flex-direction: column-reverse;
    gap: 30px
  }
}


/* homepage css*/
.sec01,
.sec04 {
  /* background-color: #000; */
  padding: 40px 0px;
}



.banner_btn:hover {
  background-color: var(--primary-btn-hover);
  color: var(--primary-color);
  border:1px solid var(--primary-color);
}

.register:hover{
  background-color: #c8f4ff30;
  color: var(--primary-color);
}

.banner_btn{
  background-color: var(--btn-bg);
  color: #000;
  margin-top: 20px;
  padding: 9px 34px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  display: inline-block;
}
.sb{
  color: var(--text-primary);
}

.banner_text {
  font-size: 15px;
}

.market_title {
  font-size: 42px;
}

.icon_box-jaw {
  padding: 70px 15px 20px;
  border-radius: 10px;
  position: relative;
  background-color: var(--card-bg1);
  border-bottom: 2px solid var(--border-color);
}

.text-center {
  text-align: center !important;
}

.profile_box_title {
  font-size: 18px;
}

.w {
  color: var(--text-color);
}

.gr {
  color: #696363;
}

.banner_text {
  font-size: 15px;
}

.jaw_icon_box_img_box {
  background: var(--card-bg1);
  width: 85px;
  height: 85px;
  object-fit: contain;
  border: 9px solid var(--theme-bg);
  padding: 12px;
  border-radius: 100px;
  position: absolute;
  top: -1%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.jaw_icon_box_img_box img {
  width: 100%;
}

.align-self {
  align-self: center;
}

.icon_box-jaw:hover .jaw_icon_box_img_box {
  border-color: var(--border-color);
}

.profile_box1,
.profile_box4 {
  padding: 40px 20px;
}

.profile_box {
  background-color: var(--card-bg);
  border-radius: 10px;
  box-shadow: 0px 0px 12px -4px var(--primary-color)3d;
}

.profile_box:hover {
  box-shadow: 0px 0px 14px -1px var(--primary-color)3d;
}

.profile_box2,
.profile_box3 {
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.text-center {
  text-align: center !important;
}

.data-center {
  display: block;
  width: 100%;
  color: var(--text-color);
}

.profile_box_title {
  font-size: 18px;
}

/* .market-table-footer p { max-width: 1000px;
                          margin: auto;
                        width: 1000px;} */

.banner_text {
  font-size: 15px;
}

.sec03,
footer {
  padding: 40px 0px;
  background-color: var(--section-bg);
}

.profile_box_col2 {
  padding-left: 40px;
}

.profile_box_icon.text-center {
  margin-right: 20px;
}

.profile_box_icon img {
  width: 70px;
}

.img-w-100 {
  width: 100px !important;
}

.margin_top {
  margin-top: 90px;
}

.app-download img {
  width: 100%;
}

.sec-pb-0 {
  padding-bottom: 0px !important;
}

.sec05 {
  background-color: var(--section-bg);
  padding: 40px 0px;
}
.sec05-1 {
  background-color: var(--bg-lightblack);
  padding: 40px 0px;
}
.outfit {
  font-family: "Outfit", sans-serif;
}

.chart_box {
  background-color: var(--card-bg1);
  padding: 0px 0px 0px 0px;
  border-radius: 15px;
  border-bottom: 2px solid var(--primary-color);
}

.sb.ada_bg {
  font-size: 10px;
  padding: 7px;
  background-color: var(--card-bg);
  border-radius: 5px;
}

.g {
  color: var(--color-success);
}

.cardano1 {
  display: inline-block;
  margin: 12px 12px 0 20px;
}

.sign_in {
  background-color: #c8f4ff30;
  color: var(--primary-color);
}

.header_btn {
  padding: 9px 34px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  display: inline-block;
  margin-top: 18px;
}

.register {
  background-color: var(--primary-color);
  color: var(--text-black);
  margin-left: 20px;
}

.sign_in:hover {
  background-color: var(--primary-color);
  color: var(--text-black);
}

.margin-top {
  margin-top: 45px;
}

.r {
  color:var(--color-danger);
}

img.chart1 {
  width: 100%;
  height: 114px;
}

@media (max-width: 992px) {
  .market_title {
    font-size: 34px;
  }

  .profile_box_icon img {
    width: 44px;
  }

  .profile_box_title {
    font-size: 18px;
  }

  .profile_box_icon.text-center {
    margin-right: 10px;
  }

  .img-w-100 {
    width: 50px !important;
  }

  .profile_box .banner_text {
    font-size: 14px !important;
  }
}

@media (max-width: 991px) {
  .profile_box {
    padding: 15px !important;
    margin: 10px 0px;
  }

  .user-dropdown-menu{
    left: -170px !important;
  
  }
}

@media (max-width: 767px) {
  .profile_box_col2 {
    padding: 25px 0px 0px;
  }

  .banner_text {
    font-size: 12px !important;
  }

  .b-image {
    width: 100%;
  }

  .b-image img {
    width: 100% !important;
  }

  .market_sub_title {
    font-size: 15px;
  }

  .market_title {
    font-size: 22px;
  }

  .chart_box2,
  .chart_box3 {
    margin-top: 20px;
  }

  .margin-top {
    margin-top: 30px;
  }

  .margin_top {
    margin-top: -40px;
  }

  .icon_box-jaw {
    margin-top: 60px;

  }

  .header_btn {
    font-size: 16px;
    margin-top: 8px;
  }

  .banner_btn {
    margin-top: 8px;
    font-size: 16px;
  }

  .col_b_1 {
    order: 2;
  }

  .column-reverse {
    flex-direction: column-reverse;
  }

  .app-download img {
    padding-top: 40px;
  }

  .profile_box {
    justify-content: space-around;
  }

}

.align_end {
  align-self: end;
}

.show-kyc-status{
  font-size: 13px !important;
  margin-left: 5px;
  /* margin-top: 2px; */
}

button.btn-close.text-reset {
  position: absolute;
  right: 3px;
  top: 11px;
  padding: 10px 20px !important;
}
button.btn-close.text-reset:focus{
  outline: none !important;
  box-shadow: none !important;
}
.offcanvas-header {
  justify-content: space-between;
}
.offcanvas-header img{
  width: 100px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .profile_box{
      padding:10px 10px !important;
      gap: 5px !important;
      flex-direction: column;
      margin: 0px !important;
      height: 220px;

    } 
    .profile_box3 {
      margin-top: 10px !important;
    }
    .profile_box4 {
      margin-top: 10px !important;
    }
}
@media screen and (min-width: 1100px) and (max-width: 1290px) { 
  .icon_box-jaw{
    height: 200px !important;
  }
}
@media screen and (min-width: 1050px) and (max-width: 1100px) { 
  .icon_box-jaw{
    height: 220px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1050px) { 
  .icon_box-jaw{
    height: 250px !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 992px) { 
  .banner_heading{
    font-size: 20px !important;
  }
  .banner_text{
    font-size: 12px !important;
  } 
  .profile_box{
    flex-direction: column;
    padding: 10px !important;
    height: 163px !important;
  }
  .profile_box_col2 {
    padding-left: 0px;
}
.col-m-100{
  margin-top: 70px !important;
}
}

@media screen and (min-width: 576px) and (max-width: 767px) { 
  .chart_box{
    height: 200px !important;
    margin-top: 0px !important;
  } 
}

/* .email-span{
  word-wrap: break-word;
} */

@media screen and  (min-width: 380px) and (max-width: 500px){
  .offcanvas{
    width: 300px !important;
  }
  .m-login-btn{
    padding: 5px 20px !important;
  }
}
@media screen and  (min-width: 320px) and (max-width: 380px){
  .offcanvas{
    width: 270px !important;
  }
  .m-login-btn{
    padding: 6px 20px !important;
  }
}