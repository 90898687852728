:root {
    --background-color:#000 !important;
    --primary-color:#08d1d1;
    --secondary:#08d1d1;
    --btn-bg:#08d1d1;
    --text-color:#fff;
    --header-bg:#000;
  
  }
  
  [data-theme="dark"] {
    --background-color:#000 !important;
    --primary-color:#08d1d1;
    --secondary:#08d1d1;
    --theme-bg:#000;
  
    --btn-bg:#08d1d1;
    --btn-bg-light:#08d1d1;
    --btn-hover:#08d1d1;
    --btn-border:#08d1d1;
    --text-primary:#08d1d1;
    --text-color:#fff;
    --text-white:#fff;
    --text-black:#000;
    --theme-text:#000;
    --color-success:#22bb33;
    --color-danger:#f94747;
  
    --header-bg:#00191e;
    --footer-bg:#00191e;
    --section-bg:#00191e;
    --card-bg:#000;
    --card-bg1:#00191e;
  
    --border-color:#08d1d1;
  
    --input-bg:#00191e;
    --input-border:#00191e;
    --input-color:#c4c4c4;


    --border-c:#202423;
    --border-buy:#023e3e;
    --table-hover:#000;
    


  }
  
  [data-theme="light"] {
    --background-color:#fff !important;
    --primary-color:#08d1d1;
    --secondary:#08d1d1;
    --theme-bg:#fff;
  
    --btn-bg:#08d1d1;
    --btn-bg-light:#08d1d1;
    --btn-hover:#08d1d1;
    --btn-border:#08d1d1;
  
    --text-primary:#08d1d1;
    --text-color:#000;
    --text-white:#fff;
    --text-black:#000;
    --theme-text:#fff;
    --color-success:#22bb33;
    --color-danger:#f94747;
  
  
    --header-bg:#f7f7f7;
    --footer-bg:#f7f7f7;
    --section-bg:#f7f7f7;
    --card-bg:#e3e6e6;
    --card-bg1:#f7f7f7;
  
    --border-color:#08d1d1;
  
    --input-bg:#f7f7f7;
    --input-border:#f7f7f7;
    --input-color:#1c1717;

    --border-c:#dadada;
    --border-buy:#d3d3d3;
    --table-hover:#e8e8e8;
  }


.modal-headers{
    color:var(--text-color)
}
  
.inner-header {
    background-color: var(--background-color);
}

.inner-menu {}

.inner-menu ul {
    display: flex;
    gap: 30px;
    padding: 10px 0px;
    align-items: center;
    margin: 0;
}

.inner-menu ul li {
    list-style: none;
}

.inner-menu ul a {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;

}

.deposit-page-main {
    background: var(--background-color);
    padding: 50px 0px;
}

.de-page-title h2 {
    font-size: 25px;
    color: var(--text-color);
}

.dep-row {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 30px;
}

.dep-left {
    width: 60%;
    background: var(--card-bg1);
    border-radius: 10px;
    padding: 20px;
}
.dep-inners-flex {
    display: flex;
    align-items: center;
    gap: 30px;
}


.dep-inner-flex {
    display: flex;
    align-items: center;
    gap: 30px;
}
.labels-div {
    width: 70%;
}
.labels-div span {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
}
.useremail {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
}


.label-div {
    width: 25%;
}

.label-div span {
    
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
}

.input-div {
    width: 75%;
}

.dep-left-form label {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
}

.dep-left-form input {
    /* background: #000 !important; */
    border-color: #000 !important;

    border: 0px !important;
    font-size: 14px !important;
    font-weight: 400;
    color: var(--input-color);
}

.dep-left-form input::placeholder {
    color: var(--input-color) !important;
}

.dep-left-form input:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 0px !important;
}

.address-div {
    position: relative;
}

.address-div li {
    list-style: none;
    background-color: var(--input-bg);
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500px;
    border:1px solid var(--border-buy)
}

.address-div span {
    position: absolute;
    right: 5px;
    bottom: 5px;
    border: 1px solid var(--primary-color);
    display: flex;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #00e6e6;
}

.address-div span:hover {
    background-color: #00e6e6;
    color: #000;
}

.scaner {
    text-align: center;
    width: 30%;
    margin: 0px auto;
    margin-top: 10px;


}

.scan-image img {
    width: 100px;
}

.scan-image {
    background: #fff;
    border-radius: 10px;
    padding: 10px;

}

.input-div input {}

.input-div-1 input {
    background: transparent !important;
    border: 1px solid var(--border-buy) !important;
    font-size: 14px !important;
    font-weight: 400;
    color: var(--input-color) !important;
    width: 100% !important;
    padding: 8px 10px;
}

.input-div-1 input::placeholder {
    color: var(--input-color) !important;
}

.input-div-1 input:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid var(--border-buy)!important;
}

.w-footer-main {
    display: flex;
    justify-content: space-between;
}

.w-footer-left li {
    list-style: none;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
}

.mini{
 color: var(--text-color);
 font-size: 15px;
}

.w-footer-left p {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 300;
}

.w-table-heading h3 {
    padding: 30px 0;
}

.dep-right {
    width: 40%;
    text-align: left;
}

.dep-right h4 {
    color: var(--text-color);
    margin-bottom: 20px;
}

.paragraph-div {}

.paragraph-div ul {
    padding: 0;
    margin: 0;
}

.paragraph-div ul li {
    list-style: none;
    display: flex;
    justify-content: flex-start;

    color: var(--text-color);
    font-size: 15px;
    font-weight: 400;
    margin-top: 15px;
}

.ic {
    width: 30px;
}

.right-arrow-icon {
    display: inline-block;
}

/*deposit history withdraw history*/

.history-header {
    padding: 100px 0px 0px 0px;
}

.history-header ul {
    padding: 0px;
}

.history-header ul li {
    display: inline-block;
    margin-left: 30px;

}

.history-header ul li:first-child {
    margin-left: 0px;
}

.history-header ul li a {
    color: var(--text-color);
}

.active-lish-history a {
    color: var(--primary-color) !important;
}

.active-lish-history {
    position: relative;
}

.active-lish-history::after {
    content: '';
    position: absolute;
    background-color: var(--primary-color);
    width: 100%;
    height: 1px;
    bottom: -10px;
    left: 0;
}

.input-div-flex {
    display: flex;
    gap: 10px;
    align-items: end;
}

.withraw-otp {
    padding: 4px 20px !important;
}


@media (max-width: 992px) {
    .dep-row {
        flex-direction: column;
    }

    .dep-left {
        width: auto;
    }

    .dep-right {
        width: 100%;
    }

    .scaner {
        width: auto;
    }

    .history-header {
        padding-top: 40px;
    }
}


@media (max-width: 576px) {
    .dep-inner-flex {
        flex-direction: column;
        align-items: start;
    }

    .label-div{
        display: none;
        width: 100%;
    }

    .input-div {
        width: 100%;
    }

    .de-page-title h2 {
        font-size: 20px;
    }

    .paragraph-div ul li {
        font-size: 13px;
    }
}

@media (max-width: 576px) {
    .inner-menu {
        display: none;
    }

   
}
